import PureCounter from "@srexi/purecounterjs";
import { useEffect } from "react";

function Stats() {
  useEffect(() => {
    new PureCounter();
  }, []);
  return (
    <>
      <section className="facts section-bg">
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="row counters d-flex align-items-center">
            <div
              className="col-lg-2 col-md-6 col-6 text-center"
              data-aos="zoom-in"
            >
              <img
                alt=""
                src="./assets/img/iso_1.svg"
                width="120px"
                height="120px"
              />
            </div>
            <div
              className="col-lg-2 col-md-6 col-6 text-center"
              style={{ transform: "scale(150%)" }}
            >
              <img
                alt=""
                src="./assets/img/iso_2.svg"
                width="120px"
                height="120px"
                data-aos="zoom-in"
              />
            </div>
            <div className="col-lg-2 col-md-6 col-6 text-center">
              <span
                data-purecounter-start={0}
                data-purecounter-end={232}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p style={{ margin: 0 }}>Clients</p>
            </div>
            <div className="col-lg-2 col-md-6 col-6 text-center">
              <span
                data-purecounter-start={0}
                data-purecounter-end={521}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Projects</p>
            </div>
            <div className="col-lg-2 col-md-6 col-6 text-center">
              <span
                data-purecounter-start={0}
                data-purecounter-end={1463}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Hours Of Support</p>
            </div>
            <div className="col-lg-2 col-md-6 col-6 text-center">
              <span
                data-purecounter-start={0}
                data-purecounter-end={25}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Hard Workers</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Stats;
