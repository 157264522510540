import { Link } from "react-router-dom";

function ContactUs() {
  return (
    <>
      <section
        id="cta"
        class="cta"
        style={{
          backgroundImage: `linear-gradient(to right,#0d181ace, #0d181ace), url("${process.env.PUBLIC_URL}/assets/img/home-contact-us/contact.jpg")`,
          backgroundPosition: "0 15%",
          backgroundSize: "cover",
          bcckgroundRepeat: "none",
        }}
      >
        <div class="container" data-aos="zoom-in">
          <div class="text-center">
            <h3 style={{ color: "white" }}>
              Need Assistance and Learn More...?
            </h3>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
                color: "white",
                width: "70%",
                margin: "32px auto 0 auto",
              }}
            >
              We're here to help! Get in touch with our dedicated team at
              BINLYTIX by filling out the contact-us form or reaching out via
              email or phone. We value your feedback and are committed to
              providing you with exceptional service. Let's collaborate to
              unlock the full potential of your business.
            </p>
            <Link
              class="cta-btn scrollto"
              to="/contact-us"
              style={{
                color: "black",
                backgroundColor: "#eacf27",
              }}
            >
              Make an Appointment
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
export default ContactUs;
