import { useEffect } from "react";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import ContactUs from "../../Layouts/ContactUs";
import { Link } from "react-router-dom";
function CloudServices() {
  useEffect(() => {
    new Swiper(".tech-carousel", {
      speed: 1000,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",

      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
    });
  }, []);
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Services</li>
              <li>Cloud Services</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero-services d-flex justify-cntent-center align-items-center"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(
            to left,
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 0.8)
          ),
          url(${process.env.PUBLIC_URL}/assets/img/cloud-technologies/cloud_technologies.jpg)`,
          backgroundPosition: "0 50%",
        }}
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: "600",
                }}
                className="animate__animated animate__fadeInDown"
              >
                Cloud Services
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Cloud solutions deliver 1.7 times the ROI of on-premise
                solutions. Achieve economies of scale, and reduce infrastructure
                spending with cloud computing.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-integrations-solutions"
        className="services-cloud-solutions"
      >
        <div className="container">
          <div className="row">
            <h3 className="col-lg-12">Cloud Solutions</h3>
            <ul className="col-lg-12">
              <li>
                <i className="bi bi-check2-circle" />
                Amazon Web Services(AWS)
              </li>
              <li>
                <i className="bi bi-check2-circle" />
                Microsoft Azure
              </li>
              <li>
                <i className="bi bi-check2-circle" />
                Oracle Cloud
              </li>
              <li>
                <i className="bi bi-check2-circle" />
                Cloud Integrations
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section
        id="services-integrations-approach"
        className="services-cloud-approach"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Approach</h2>
          </div>
          <div className="row" data-aos="fade-up">
            <p className="col-lg-12">
              Developing a successful cloud solutions approach involves
              considering various factors such as business goals, technical
              requirements, scalability, security, and user experience. Here's
              our step-by-step approach to building effective cloud solutions.
            </p>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Assessment &amp; Planning</h3>
                <p>
                  We identify business needs &amp; goals, evaluate existing
                  infrastructure , determine which processes or services to
                  migrate or build in the cloud.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Selecting Cloud Model</h3>
                <p>
                  We choose between public, private, hybrid or multi-cloud &amp;
                  consider factors like security, compliance, cost &amp; select
                  provider accordingly.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>App Development</h3>
                <p>
                  Develop scalable cloud-native apps or refactor existing ones
                  that scale application's resources based on demand . Migrate
                  data and services to the cloud environment.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Security &amp; Compliance</h3>
                <p>
                  Implement robust security measures with Data Encryption.
                  Ensure compliance with regulations.Scale resources based on
                  demand.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>UX &amp; Performance</h3>
                <p>
                  Design responsive user interfaces with cuting edge
                  technologies. Optimize application performance for fast
                  response times and smooth interactions.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Monitoring &amp; Analytics</h3>
                <p>
                  Set up tools to track the performance metrics of your cloud
                  services, such as response times, latency, and resource
                  utilization. Use analytics for insights.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Our Support &amp; Training</h3>
                <p>
                  We provide customer support channels for technical assistance
                  &amp; train staff with role-specific training &amp; best
                  practices to use cloud services effectively.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Regular Improvement</h3>
                <p>
                  Regularly assess and adapt to business needs by using
                  analytics, security audits &amp; resource optimization.
                  Incorporate feedback for enhancements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-cloud"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Specialization</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                A successful cloud solutions approach involves a systematic
                process of assessment, planning, design, implementation, and
                ongoing improvement to leverage the benefits of cloud technology
                for your organization.
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bx bx-check" />
                  Cloud Infrastructure Services
                </div>
                <p>
                  Leverage our scalable and reliable cloud infrastructure to
                  host your applications, websites, and databases, ensuring high
                  availability and performance.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Cloud Migration
                </div>
                <p>
                  Seamlessly migrate your existing on-premises infrastructure or
                  applications to the cloud, minimizing downtime and maximizing
                  efficiency.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Cloud Backup &amp; Disaster Recovery
                </div>
                <p>
                  Safeguard your critical data with our secure cloud backup
                  solutions and establish robust disaster recovery plans to
                  ensure business continuity.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Cloud Security Solutions
                </div>
                <p>
                  Protect your cloud environment with our advanced security
                  services, including encryption, access controls, and
                  continuous monitoring.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="our-tech" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Technologies We Use</h2>
          </div>
          <div className="tech-carousel swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/aws.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>AWS</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/azure.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Azure</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      border: "none",
                      rowGap: "32px",
                    }}
                    alt=""
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/google-cloud.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Google Cloud</p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/oracle-cloud.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Oracle Cloud</p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/docker.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Docker</p>
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default CloudServices;
