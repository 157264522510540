import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function Travel() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Industries</li>
              <li>Tour &amp; Travel</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left, rgba(0,0, 0, 0.7),rgba(0, 0, 0, 0.7)),url(${process.env.PUBLIC_URL}/assets/img/industries/travel.jpg)`,
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Tour & Travel
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Welcome to BINLYTIX, where we are dedicated to transforming the
                travel and tours industry through cutting-edge software
                solutions. Our passion for innovation and our deep understanding
                of the travel landscape position us as a driving force in
                shaping the future of travel planning, booking, and customer
                experiences.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Travel Booking Platforms</a>
                </h4>
                <p className="description">
                  BINLYTIX, your gateway to seamless travel booking solutions by
                  offering advanced software platforms that enhance travel
                  businesses to offer streamlined booking experiences &amp;
                  connect with travelers
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>Personalized Travel Recommendations</a>
                </h4>
                <p className="description">
                  At BINLYTIX, we make travel ease with personalization. We
                  offer advanced software solutions that empower travelers to
                  embark on journeys tailored to their unique preferences,
                  interests &amp; aspirations.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Travel Analytics &amp; Insights</a>
                </h4>
                <p className="description">
                  Our solutions provide travel businesses with valuable insights
                  into traveler behavior, preferences, and trends, enabling them
                  to make informed strategic decisions.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>Mobile Apps for Travelers</a>
                </h4>
                <p className="description">
                  BINLYTIX, your partner in enhancing travel experiences through
                  mobile apps. Our advanced software solutions will enhance
                  experience to explore, plan &amp; navigate their journeys with
                  convenience &amp; ease.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                At BINLYTIX, we recognize the pivotal role that software plays
                in enhancing travel experiences. Here's how we contribute to the
                growth and evolution of the travel industry:
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bi bi-check" />
                  Travel Booking Platforms
                </div>
                <p>
                  We create user-friendly, responsive travel booking platforms
                  that empower travelers to search for, compare, and book
                  flights, accommodations, and activities seamlessly, fostering
                  convenience and choice.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Tour Management Systems
                </div>
                <p>
                  Our software solutions enable tour operators to efficiently
                  manage their offerings, from creating itineraries and managing
                  bookings to ensuring smooth on-ground operations for memorable
                  travel experiences.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Personalized Travel Recommendations
                </div>
                <p>
                  We harness the power of data analytics to offer personalized
                  travel recommendations based on traveler preferences, creating
                  tailored itineraries that cater to unique interests.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Mobile Apps for Travelers
                </div>
                <p>
                  We develop feature-rich mobile apps that provide travelers
                  with real-time information, maps, local guides, and essential
                  travel details, enhancing their journey every step of the way.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Payment &amp; Financial Security
                </div>
                <p>
                  Our software ensures secure and hassle-free payment
                  processing, safeguarding financial transactions and boosting
                  traveler confidence in online bookings.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Virtual Travel Experiences
                </div>
                <p>
                  We enable virtual tours and experiences that allow travelers
                  to explore destinations before their trip, offering a sneak
                  peek into attractions and helping them make informed
                  decisions.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Travel Analytics &amp; Insights
                </div>
                <p>
                  Our solutions provide travel businesses with valuable insights
                  into traveler behavior, preferences, and trends, enabling them
                  to make informed strategic decisions.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default Travel;
