import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  function title(arg) {
    document.title = arg;
  }
  useEffect(() => {
    title("404 - Page Not found");
    return () =>
      title("BINLYTIX – BI Analytics and Software Development Solutions");
  }, []);

  return (
    <div
      className="container not-found"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1 style={{ color: "#074958" }}>404 - Page Not Found</h1>
      <svg
        width="400"
        height="400"
        viewBox="0 0 900 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="transparent" d="M0 0h900v600H0z" />
        <circle cx="451.426" cy="300" r="262.123" fill="url(#a)" />
        <path
          d="M708.475 240.202c-12.627 22.535-40.281 25.631-84.585 21.349-33.32-3.227-63.705-5.724-97.027-23.892-23.323-12.707-41.785-29.899-55.26-46.597-14.602-18.089-34.983-38.734-24.992-59.245 13.73-28.174 93.133-51.904 170.236-13.099 84.698 42.641 103.981 99.459 91.628 121.484z"
          fill="url(#b)"
        />
        <path
          d="M793.833 287.316c-23.788 11.8-55.36-3.373-55.36-3.373s7.016-34.297 30.817-46.081c23.787-11.8 55.347 3.356 55.347 3.356s-7.017 34.298-30.804 46.098z"
          fill="url(#c)"
        />
        <path
          d="M118.142 373.556c31.081 18.628 75.673 1.408 75.673 1.408s-5.831-47.416-36.931-66.023c-31.081-18.629-75.654-1.429-75.654-1.429s5.83 47.416 36.912 66.044z"
          fill="url(#d)"
        />
        <circle
          cx="747.028"
          cy="208.246"
          r="12.989"
          transform="rotate(180 747.028 208.246)"
          fill="#074958"
        />
        <circle
          cx="257.822"
          cy="281.817"
          r="14.612"
          transform="rotate(180 257.822 281.817)"
          fill="#074958"
        />
        <circle
          r="12.177"
          transform="matrix(-1 0 0 1 159.929 195.932)"
          fill="#074958"
        />
        <circle
          r="5.683"
          transform="matrix(-1 0 0 1 635.635 259.251)"
          fill="#074958"
        />
        <circle
          r="7.306"
          transform="matrix(-1 0 0 1 668.43 512.81)"
          fill="#E1E4E5"
        />
        <circle
          r="10.553"
          transform="matrix(-1 0 0 1 157.751 436.948)"
          fill="#E1E4E5"
        />
        <circle
          r="8.032"
          transform="matrix(-1 0 0 1 279.793 167.303)"
          fill="#E1E4E5"
        />
        <circle
          r="8.93"
          transform="matrix(-1 0 0 1 669.743 168.793)"
          fill="#E1E4E5"
        />
        <circle
          r="8.019"
          transform="scale(1 -1) rotate(-75 -85.053 -253.614)"
          fill="#E1E4E5"
        />
        <circle
          r="10.668"
          transform="matrix(-1 0 0 1 385.553 99.921)"
          fill="#E1E4E5"
        />
        <ellipse
          rx="8.206"
          ry="6.565"
          transform="matrix(-1 0 0 1 725.744 372.599)"
          fill="#E1E4E5"
        />
        <circle
          r="16.689"
          transform="scale(1 -1) rotate(-75 220.696 -421.766)"
          fill="#E1E4E5"
        />
        <path
          d="M787.973 327.261h.214c1.271 18.011 14.666 18.288 14.666 18.288s-14.77.288-14.77 21.1c0-20.812-14.771-21.1-14.771-21.1s13.389-.277 14.661-18.288zM248.445 502.359h.19c1.128 16.301 13.014 16.552 13.014 16.552s-13.106.261-13.106 19.096c0-18.835-13.106-19.096-13.106-19.096s11.88-.251 13.008-16.552z"
          fill="#E1E4E5"
        />
        <rect
          x="221.128"
          y="128.304"
          width="461.792"
          height="344.342"
          rx="4.194"
          fill="#fff"
          stroke="#E1E4E5"
          stroke-width="4"
        />
        <path
          d="M404.574 247.969v23.992m95.978-23.992v23.992m35.801 108.38s-31.442-30.145-83.879-30.145c-52.436 0-83.879 30.145-83.879 30.145"
          stroke="#074958"
          stroke-width="33.798"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="221.128"
          y="128.304"
          width="461.792"
          height="40.04"
          rx="4.194"
          fill="#fff"
          stroke="#E1E4E5"
          stroke-width="4"
        />
        <rect
          x="237.144"
          y="141.65"
          width="13.347"
          height="13.347"
          rx="6.673"
          fill="#fff"
          stroke="#E1E4E5"
          stroke-width="4.194"
        />
        <rect
          x="261.168"
          y="141.65"
          width="13.347"
          height="13.347"
          rx="6.673"
          fill="#fff"
          stroke="#E1E4E5"
          stroke-width="4.194"
        />
        <rect
          x="285.191"
          y="141.65"
          width="13.347"
          height="13.347"
          rx="6.673"
          fill="#fff"
          stroke="#E1E4E5"
          stroke-width="4.194"
        />
        <defs>
          <linearGradient
            id="a"
            x1="462.603"
            y1="856.045"
            x2="446.439"
            y2="-532.412"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#fff" />
            <stop offset="1" stop-color="#EEE" />
          </linearGradient>
          <linearGradient
            id="b"
            x1="623.412"
            y1="386.251"
            x2="510.865"
            y2="-119.486"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#fff" />
            <stop offset="1" stop-color="#EEE" />
          </linearGradient>
          <linearGradient
            id="c"
            x1="702.485"
            y1="324.123"
            x2="898.687"
            y2="168.927"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#fff" />
            <stop offset="1" stop-color="#EEE" />
          </linearGradient>
          <linearGradient
            id="d"
            x1="238.418"
            y1="433.519"
            x2="-11.673"
            y2="201.151"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#fff" />
            <stop offset="1" stop-color="#EEE" />
          </linearGradient>
        </defs>
      </svg>
      <p>
        Oops! The page you are looking for might have been removed or its name
        changed or is temporarily unavailable.
      </p>
      <Link to="/" style={{ backgroundColor: "#074958" }}>
        Go to Home
      </Link>
    </div>
  );
};
export default NotFound;
