import { Link } from "react-router-dom";

function Hero() {
  return (
    <>
      <div className="hero-wrap hero-home">
        <section
          id="hero"
          className="d-flex justify-cntent-center align-items-center home-hero"
        >
          <div
            id="heroCarousel"
            className="container carousel carousel-fade"
            data-bs-ride="carousel"
            data-bs-interval={5000}
          >
            <div className="carousel-item active">
              <div className="carousel-container">
                <h2
                  style={{
                    marginBottom: "3rem",
                    fontSize: "4rem",
                    fontWeight: 600,
                  }}
                  data-aos="fade-down"
                >
                  Welcome to <span>BINLYTIX</span>
                </h2>
                <p
                  className="animate__animated animate__fadeInUp home-hero"
                  data-aos="fade-up"
                >
                  Binlytix is an analytics and software solution provider
                  company, serving the wide range of industries across the globe
                  from half a decade.
                </p>
                <Link
                  to="about-us"
                  className="btn-get-started animate__animated animate__fadeInUp"
                  data-aos="fade-up"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-container">
                <h1
                  style={{
                    marginBottom: "3rem",
                    fontSize: "4rem",
                    fontWeight: 600,
                  }}
                  className="animate__animated animate__fadeInDown"
                  data-aos="fade-up"
                >
                  Business Analytics
                </h1>
                <p
                  className="animate__animated animate__fadeInUp home-hero"
                  data-aos="fade-up"
                >
                  Analyze, visualize, and make informed decisions with business
                  intelligence and analytics. Instant visibility on business
                  performance and for better business outcomes.
                </p>
                <Link
                  to="services/business-intelligence-and-analytics"
                  className="btn-get-started animate__animated animate__fadeInUp"
                  data-aos="fade-up"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-container">
                <h2
                  className="animate__animated animate__fadeInDown"
                  data-aos="fade-up"
                >
                  Software Development and Maintenance
                </h2>
                <p
                  className="animate__animated animate__fadeInUp home-hero"
                  data-aos="fade-up"
                >
                  We offer customized Software Solutions to help our clients
                  achieve their goals and our experienced professionals is
                  committed to delivering high-quality solutions that are
                  tailored to meet the specific requirements of our clients.
                </p>
                <Link
                  to="services/software-development-and-maintenance"
                  className="btn-get-started animate__animated animate__fadeInUp"
                  data-aos="fade-up"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-container">
                <h2
                  className="animate__animated animate__fadeInDown"
                  data-aos="fade-up"
                >
                  Software Integration
                </h2>
                <p
                  className="animate__animated animate__fadeInUp home-hero"
                  data-aos="fade-up"
                >
                  Establish efficient business process and break down silos
                  within your workflows. Enable process management, reduce IT
                  complexity, and increase IT agility.
                </p>
                <Link
                  to="services/software-integrations"
                  className="btn-get-started animate__animated animate__fadeInUp"
                  data-aos="fade-up"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-container">
                <h2
                  className="animate__animated animate__fadeInDown"
                  data-aos="fade-up"
                >
                  Cloud Services
                </h2>
                <p
                  className="animate__animated animate__fadeInUp home-hero"
                  data-aos="fade-up"
                >
                  Cloud solutions deliver 1.7 times the ROI of on-premise
                  solutions. Achieve economies of scale, and reduce
                  infrastructure spending with cloud computing.
                </p>
                <Link
                  to="services/cloud-services"
                  className="btn-get-started animate__animated animate__fadeInUp"
                  data-aos="fade-up"
                >
                  Read More
                </Link>
              </div>
            </div>
            <Link
              className="carousel-control-prev"
              to="#heroCarousel"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon bx bx-chevron-left"
                aria-hidden="true"
                style={{ color: "white" }}
              />
            </Link>
            <Link
              className="carousel-control-next"
              to="#heroCarousel"
              role="button"
              data-bs-slide="next"
            >
              <span
                style={{ color: "white" }}
                className="carousel-control-next-icon bx bx-chevron-right"
                aria-hidden="true"
              />
            </Link>
          </div>
        </section>
        <video
          autoPlay
          muted
          loop
          id="myVideo"
          data-aos="fade-zoom-in"
          classname="img-fluid"
        >
          <source src="assets/img/hero_video.mp4" type="video/mp4" />
        </video>
      </div>
    </>
  );
}
export default Hero;
