import { useEffect } from "react";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import ContactUs from "../../Layouts/ContactUs";
import { Link } from "react-router-dom";

function SoftwareDevelopment() {
  useEffect(() => {
    new Swiper(".tech-carousel", {
      speed: 1000,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",

      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
    });
  }, []);
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Services</li>
              <li>Software Development &amp; Maintainance</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero-services d-flex justify-cntent-center align-items-center"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(
            to left,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7)
          ),
          url(${process.env.PUBLIC_URL}/assets/img/software-development/software_development.jpg)`,
        }}
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: "600",
                }}
                className="animate__animated animate__fadeInDown"
              >
                Software development and maintenance
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                We offer customized Software Solutions to help our clients
                achieve their goals and our experienced professionals is
                committed to delivering high-quality solutions that are tailored
                to meet the specific requirements of our clients.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="featured-services" className="featured-services">
        <div className="container">
          <div className="col-lg-12 section-title">
            <h2>Software Solutions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom">
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/content.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">
                    Content Management System Software(CMS)
                  </Link>
                </h4>
                <p>
                  We understand the pivotal role content plays in your online
                  success. Our Content Management System is designed to simplify
                  the creation, editing, and organization of your digital
                  content, allowing you to focus on what matters most – your
                  business.
                </p>
              </div>
            </div>
            {/* End Service Item */}
            <div
              className="col-xl-4 col-md-6 d-flex"
              data-aos="zoom"
              data-aos-delay={200}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/web.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">Web based ERP for SMBs</Link>
                </h4>
                <p>
                  At BINLYTIX, we understand the unique challenges faced by
                  SMBs. Our web-based ERP solution is designed to simplify and
                  enhance your business processes, providing a comprehensive
                  suite of tools to fuel your growth and success.
                </p>
              </div>
            </div>
            {/* End Service Item */}
            <div
              className="col-xl-4 col-md-6 d-flex"
              data-aos="zoom"
              data-aos-delay={400}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/gps.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">
                    GPS and GIS based applications
                  </Link>
                </h4>
                <p>
                  We harness the power of GPS and GIS technologies to bring you
                  innovative applications that revolutionize the way you
                  navigate, analyze, and visualize geographical data.Whether
                  you're an outdoor enthusiast, a logistics professional or a
                  traveler, we offer seamless and accurate navigation
                </p>
              </div>
            </div>
            {/* End Service Item */}
            <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom">
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/ecommerce.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">E-commerce</Link>
                </h4>
                <p>
                  We redefine online shopping by offering a curated selection
                  that combines quality, affordability, and the latest trends.
                  Explore our virtual aisles to find the perfect products that
                  suit your lifestyle.
                </p>
              </div>
            </div>
            {/* End Service Item */}
            <div
              className="col-xl-4 col-md-6 d-flex"
              data-aos="zoom"
              data-aos-delay={200}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/customer.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">
                    Customer Intelligence System
                  </Link>
                </h4>
                <p>
                  Our advanced analytics & cutting-edge technologies empower you
                  to understand, connect with & delight your customers like
                  never before by gathering, analyzing, & interpreting data to
                  derive actionable insights that inform strategic
                  decision-making.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-6 d-flex"
              data-aos="zoom"
              data-aos-delay={400}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/sales.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">
                    Sales Intelligence System
                  </Link>
                </h4>
                <p>
                  BINLYTIX will be your partner in leveraging cutting-edge sales
                  intelligence solutions. Uncover new opportunities, understand
                  your customers better, and supercharge your sales efforts with
                  our advanced tools & analytics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="software-dev-life-cycle">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-12 section-title">
              <h2>Our Methodology</h2>
            </div>
            <div className="col-lg-12 content">
              <p>
                The Software Development Life Cycle (SDLC) is a structured
                approach to developing and managing software projects. It
                provides a set of well-defined phases and activities to guide
                the development process from conception to deployment and
                maintenance. The SDLC aims to ensure that software projects are
                completed on time, within budget, and with the desired level of
                quality.
              </p>
              <div className="col-lg-12 img-sldc" data-aos="fade-up">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/software-development/sldc.png`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="features">
        <div className="container" data-aos="fade-up">
          <ul className="nav nav-tabs row gy-4 d-flex">
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className="nav-link active show"
                data-bs-toggle="tab"
                data-bs-target="#tab-1"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/software-development/planning.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4>Planning</h4>
              </a>
            </li>
            {/* End Tab 1 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-2"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/software-development/analysis.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4>Analysis</h4>
              </a>
            </li>
            {/* End Tab 2 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-3"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/software-development/design.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4>Design</h4>
              </a>
            </li>
            {/* End Tab 3 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-4"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/software-development/implementation.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4>Implementation</h4>
              </a>
            </li>
            {/* End Tab 4 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-5"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/software-development/testing.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4>Testing</h4>
              </a>
            </li>
            {/* End Tab 5 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-6"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/software-development/maintenance.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4>Maintenance</h4>
              </a>
            </li>
            {/* End Tab 6 Nav */}
          </ul>
          <div className="tab-content">
            <div className="tab-pane active show" id="tab-1">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-7 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Planning</h3>
                  <p className="fst-italic">
                    The planning phase involves defining the project roadmap,
                    setting timelines, allocating resources, and creating a
                    detailed project plan. The team identifies potential risks
                    and establishes strategies to mitigate them. Additionally,
                    the project's architecture and technology stack are decided
                    in this phase.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Objectives:</strong> Define the purpose, goals,
                      and scope of the project.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Requirements Gathering:</strong>
                      Collect and document the functional and non-functional
                      requirements.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Feasibility Study:</strong>
                      Assess the technical, operational, and economic
                      feasibility of the project.
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-5 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/planning.svg`}
                    alt=""
                    className="img-fluid"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            {/* End Tab Content 1 */}
            <div className="tab-pane" id="tab-2">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-7 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Analysis</h3>
                  <p className="fst-italic">
                    In this initial phase, the development team interacts with
                    stakeholders, clients, and end-users to understand the
                    project's requirements and expectations. The team identifies
                    the scope of the project, defines the features and
                    functionalities needed, and documents the requirements in
                    detail.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>System Design: </strong> Create a high-level
                      design that outlines the system's architecture,
                      components, and data flow.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong> Detailed Requirements Specification:</strong>
                      Refine and detail the requirements to provide a
                      comprehensive blueprint for development.
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-5 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/analysis.svg`}
                    alt=""
                    className="img-fluid"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            {/* End Tab Content 2 */}
            <div className="tab-pane" id="tab-3">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-7 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Design</h3>
                  <p className="fst-italic">
                    During the design phase, the development team creates a
                    detailed blueprint of the software solution based on the
                    requirements. This includes designing the system
                    architecture, database schema, user interface, and other
                    technical specifications.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Architectural Design: </strong>
                      Develop a detailed plan for the software's structure,
                      components, and data flow.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>User Interface Design: </strong>
                      Design the user interface elements for optimal user
                      experience.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Database Design: </strong>
                      Create the database schema and define data relationships.
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-5 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/design.svg`}
                    alt=""
                    className="img-fluid"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            {/* End Tab Content 3 */}
            <div className="tab-pane" id="tab-4">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-7 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Implementation</h3>
                  <p className="fst-italic">
                    In this phase, the actual development of the software takes
                    place. Developers write code according to the design
                    specifications. This phase involves rigorous testing and
                    code reviews to ensure the quality of the code.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Coding: </strong>
                      Write and implement the source code according to the
                      design specifications.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Unit Testing: </strong>
                      Verify the correctness of individual modules or components
                      through testing.
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-5 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/implementation.svg`}
                    alt=""
                    className="img-fluid"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            {/* End Tab Content 4 */}
            <div className="tab-pane" id="tab-5">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-7 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Testing</h3>
                  <p className="fst-italic">
                    The testing phase involves various types of testing, such as
                    unit testing, integration testing, system testing, and user
                    acceptance testing (UAT). The goal is to identify and fix
                    defects and ensure that the software meets the specified
                    requirements.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Integration Testing: </strong>
                      Verify that the different modules work together as
                      intended.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>System Testing: </strong>
                      Conduct comprehensive tests to ensure the entire system
                      functions as expected.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>User Acceptance Testing (UAT): </strong>
                      Validate the system with end-users to ensure it meets
                      their requirements.
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-5 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/testing.svg`}
                    alt=""
                    className="img-fluid"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            {/* End Tab Content 5 */}
            <div className="tab-pane" id="tab-6">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-7 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Maintenance</h3>
                  <p className="fst-italic">
                    After deployment, the software enters the maintenance phase,
                    during which developers address any issues that arise in the
                    production environment, apply updates, and provide ongoing
                    support. This phase may include regular updates, bug fixes,
                    and feature enhancements.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Bug Fixes: </strong>
                      Address and resolve any issues or bugs that arise.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Enhancements: </strong>
                      Implement additional features or improvements based on
                      user feedback.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Monitoring: </strong>
                      Continuously monitor the system's performance and address
                      any emerging issues.
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-5 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/maintenance.svg`}
                    alt=""
                    className="img-fluid"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            {/* End Tab Content 6 */}
          </div>
        </div>
      </section>

      <section className="our-tech" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Technologies We Use</h2>
          </div>
          <div className="tech-carousel swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/react.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>React</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/angular.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Angular</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      border: "none",
                      rowGap: "32px",
                    }}
                    alt=""
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/java.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Java</p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/python.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Python</p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/php.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>PHP</p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/c++.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>C++</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/javascript.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Javascript</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/net.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>.NET</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/laravel.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Laravel</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/node.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Node JS</p>
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default SoftwareDevelopment;
