import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function Healthcare() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Industries</li>
              <li>Healthcare</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left, rgba(0,0, 0, 0.7),rgba(0, 0, 0, 0.7)),url(${process.env.PUBLIC_URL}/assets/img/industries/healthcare.jpg)`,
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Health Care
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                At BINLYTIX, we are dedicated to revolutionize the healthcare
                industry through cutting-edge software solutions that enhance
                patient care, streamline operations, and drive advancements in
                medical technology. With a deep understanding of the unique
                challenges faced by healthcare providers, we have developed a
                comprehensive suite of software products designed to optimize
                processes, improve outcomes, and elevate patient experiences.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Electronic Health Records (EHR)</a>
                </h4>
                <p className="description">
                  We develop and implement Electronic Health Record systems,
                  enabling healthcare providers to store and access patient
                  information electronically.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>Health Management Systems</a>
                </h4>
                <p className="description">
                  We offer comprehensive health management systems that assist
                  healthcare organizations in streamlining operations, managing
                  patient workflows, and optimizing resource allocation.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Medical Software &amp; Applications</a>
                </h4>
                <p className="description">
                  We develop specialized medical software and applications that
                  cater to various healthcare needs which helps in personalized
                  medicine precision healthcare solutions.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>Healthcare Analytics </a>
                </h4>
                <p className="description">
                  We provide healthcare analytics solutions that help medical
                  institutions gain insights from large datasets &amp; empowers
                  them to identify trends, improve operational efficiency, and
                  make informed decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                We facilitate the seamless interaction and interoperability of
                different software systems. Software integrations involve
                combining multiple software applications, services, or
                components to work together as a unified solution. This can be
                done to enhance functionality, improve efficiency, streamline
                processes, and provide a better user experience.
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bi bi-check" />
                  Electronic Health Records (EHR) and EMR Solutions
                </div>
                <p>
                  Our advanced EHR and EMR systems offer a secure and
                  user-friendly platform for healthcare providers to manage
                  patient information, streamline documentation, and ensure
                  seamless collaboration among medical teams. With customizable
                  features tailored to each facility's needs, we simplify
                  record-keeping and improve care coordination.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Telemedicine and Remote Patient Monitoring
                </div>
                <p>
                  In an era where remote care is essential, our telemedicine
                  solutions facilitate virtual consultations between patients
                  and providers, transcending geographical barriers.
                  Additionally, our remote patient monitoring software empowers
                  healthcare professionals to track vital signs and health data
                  remotely, enabling proactive care management.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Healthcare Management and Administration
                </div>
                <p>
                  Our software streamlines administrative tasks such as
                  appointment scheduling, billing, and insurance claims
                  processing. By automating these processes, we enable
                  healthcare organizations to allocate resources more
                  efficiently and improve the overall patient experience.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Clinical Decision Support Systems (CDSS)
                </div>
                <p>
                  Our CDSS software assists healthcare providers in making
                  informed clinical decisions by providing evidence-based
                  recommendations, relevant medical research, and alerts to
                  potential issues, ultimately leading to better patient
                  outcomes.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Health Analytics and Insights
                </div>
                <p>
                  Through advanced data analytics, we help healthcare
                  organizations extract meaningful insights from vast amounts of
                  data. This empowers them to identify trends, improve
                  operational efficiency, and make informed decisions that
                  positively impact patient care.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Patient Engagement &amp; Education
                </div>
                <p>
                  Our patient-centric mobile applications empower individuals to
                  take control of their health by providing access to medical
                  records, personalized health tracking, and educational
                  resources. This fosters a stronger patient-provider
                  relationship and encourages active participation in healthcare
                  management.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Medical Imaging &amp; Diagnostics
                </div>
                <p>
                  We develop software that enhances the accuracy and efficiency
                  of medical imaging analysis, aiding healthcare professionals
                  in making accurate diagnoses and treatment plans based on
                  high-quality visual data.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Security and Compliance Solutions
                </div>
                <p>
                  Recognizing the importance of data security and patient
                  privacy, our software adheres to the highest industry
                  standards. We ensure that healthcare organizations can
                  confidently manage sensitive information while complying with
                  regulations such as HIPAA.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default Healthcare;
