import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function Telecom() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Industries</li>
              <li>Telecom</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left, rgba(0,0, 0, 0.7),rgba(0, 0, 0, 0.7)),url(${process.env.PUBLIC_URL}/assets/img/industries/telecom.jpg)`,
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Telecom
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                We are at the forefront of revolutionizing the telecom industry
                through cutting-edge software solutions. Our commitment to
                enhancing connectivity, efficiency, and customer experiences
                drives us to develop tailored software solutions that address
                the unique challenges of the telecommunications sector.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Network Management &amp; Optimization</a>
                </h4>
                <p className="description">
                  We provide ultimate solution for network management &amp;
                  optimization. Our cutting-edge software empowers businesses to
                  take control of their networks, enhance performance &amp;
                  ensure seamless operations.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>Billing &amp; Revenue Management</a>
                </h4>
                <p className="description">
                  BINLYTIX, your partner in streamlining billing processes &amp;
                  optimizing revenue management. Our advanced software solutions
                  enable businesses to effectively manage billing cycles,
                  enhance revenue streams.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Mobile App Development</a>
                </h4>
                <p className="description">
                  We are transforming the telecom industry our specialized
                  solutions that empower telecom businesses to deliver
                  exceptional customer experiences, optimize operations, and
                  stay ahead of the competition.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>Data Analytics &amp; Insights</a>
                </h4>
                <p className="description">
                  We at BINLYTIX, leveraging the power of data analytics with
                  our advanced software solutions that unlock valuable insights,
                  optimize operations &amp; drive strategic decisions for
                  unparalleled success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                At BILYTIX, we recognize the pivotal role of software in shaping
                the future of telecommunications. Here's how we contribute to
                the evolution of the telecom industry:
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bi bi-check" />
                  Network Management &amp; Optimization
                </div>
                <p>
                  Our advanced software solutions empower telecom operators to
                  efficiently manage and optimize their networks. We provide
                  tools for monitoring performance, analyzing data traffic, and
                  identifying areas for improvement to deliver seamless
                  connectivity.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Billing and Revenue Management
                </div>
                <p>
                  We create comprehensive billing and revenue management systems
                  that enable telecom companies to accurately bill customers,
                  manage subscriptions, and streamline revenue collection
                  processes.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Customer Relationship Management (CRM)
                </div>
                <p>
                  Our CRM solutions are designed to enhance customer experiences
                  by providing telecom providers with a 360-degree view of
                  customer interactions, enabling personalized services and
                  improved customer satisfaction.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Mobile App Development
                </div>
                <p>
                  We develop intuitive mobile applications that empower telecom
                  customers to manage their accounts, access services, and
                  receive real-time updates, fostering engagement and loyalty.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Data Analytics and Insights
                </div>
                <p>
                  Our data analytics tools assist telecom companies in
                  extracting valuable insights from large datasets, enabling
                  them to identify trends, predict demand, and make informed
                  strategic decisions.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  IoT and Connectivity Solutions
                </div>
                <p>
                  We play a crucial role in enabling the Internet of Things
                  (IoT) by creating software that facilitates the seamless
                  connectivity and management of IoT devices, opening up new
                  avenues for innovation.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Security &amp; Fraud Prevention
                </div>
                <p>
                  We address security concerns by developing software that
                  safeguards telecom networks, data, and transactions, while
                  also implementing solutions to detect and prevent fraud.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default Telecom;
