import { Link } from "react-router-dom";

function Index() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Blog</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Blog</li>
            </ol>
          </div>
        </div>
      </section>
      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8 entries">
              <article className="entry">
                <div className="entry-img">
                  <img
                    src="assets/img/blog/blog-1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h2 className="entry-title">
                  <Link to="software-tools">
                    Unlocking Efficiency: A Comprehensive Guide to Types of
                    Software Development Tools
                  </Link>
                </h2>
                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person" />
                      <a>Rakesh</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock" />
                      <a>
                        <time dateTime="2020-01-01"> Jan 4, 2023 </time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots" />
                      <a>0 Comments</a>
                    </li>
                  </ul>
                </div>
                <div className="entry-content">
                  <p>
                    In the dynamic and fast-paced world of software development,
                    having the right set of tools can make all the difference
                    between a smooth development process and a chaotic one. In
                    this blog, we'll explore the various types of software
                    development tools that empower developers to write better
                    code, collaborate effectively, streamline workflows, and
                    deliver high-quality software products.
                  </p>
                  <div className="read-more">
                    <Link to="software-tools">Read More</Link>
                  </div>
                </div>
              </article>
              <article className="entry">
                <div className="entry-img">
                  <img
                    src="assets/img/blog/blog-2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h2 className="entry-title">
                  <Link to="perfect-tool">
                    Navigating the Maze: How to Choose the Perfect Tool for
                    Software Development
                  </Link>
                </h2>
                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person" />
                      <a>Srikanth</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock" />
                      <a>
                        <time dateTime="2020-01-01">Feb 12, 2023</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots" />
                      <a>0 Comments</a>
                    </li>
                  </ul>
                </div>
                <div className="entry-content">
                  <p>
                    Selecting the right software development tool is a critical
                    decision that can significantly impact the efficiency and
                    success of your development projects. With an ever-expanding
                    range of tools available, finding the perfect fit can be
                    daunting. In this blog, we'll walk you through a
                    step-by-step guide to help you make informed decisions and
                    choose the perfect tool for your software development needs.
                  </p>
                  <div className="read-more">
                    <Link to="perfect-tool">Read More</Link>
                  </div>
                </div>
              </article>
              <article className="entry">
                <div className="entry-img">
                  <img
                    src="assets/img/blog/blog-3.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h2 className="entry-title">
                  <Link to="full-stack">
                    Mastering Full-Stack Web Development: Best Practices for
                    Success
                  </Link>
                </h2>
                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person" />
                      <a>Sainath</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock" />
                      <a>
                        <time dateTime="2020-01-01">Mar 25, 2023</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots" />
                      <a>12 Comments</a>
                    </li>
                  </ul>
                </div>
                <div className="entry-content">
                  <p>
                    Full-stack web development is an art that combines the
                    skills of both front-end and back-end development to create
                    dynamic and interactive web applications. To excel in this
                    field, developers must adhere to best practices that not
                    only ensure the efficient functioning of their applications
                    but also contribute to maintainable and scalable code. In
                    this blog, we'll explore essential best practices for
                    mastering full-stack web development.
                  </p>
                  <div className="read-more">
                    <Link to="full-stack">Read More</Link>
                  </div>
                </div>
              </article>
              <article className="entry">
                <div className="entry-img">
                  <img
                    src="assets/img/blog/blog-4.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h2 className="entry-title">
                  <Link>
                    Unleashing the Power of Data: Business Intelligence and
                    Analytics Best Practices
                  </Link>
                </h2>
                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person" />
                      <a>Santosh</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock" />
                      <a>
                        <time dateTime="2020-01-01">July 11, 2023</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots" />
                      <a>0 Comments</a>
                    </li>
                  </ul>
                </div>
                <div className="entry-content">
                  <p>
                    In today's data-driven world, leveraging business
                    intelligence and analytics has become essential for
                    organizations aiming to gain a competitive edge and make
                    informed decisions. However, the abundance of data and
                    complexity of analytics tools can be overwhelming. In this
                    blog, we'll explore best practices to help businesses
                    effectively utilize business intelligence and analytics to
                    extract valuable insights and drive data-informed
                    strategies.
                  </p>
                  <div className="read-more">
                    <Link to="analytics">Read More</Link>
                  </div>
                </div>
              </article>
              <div className="blog-pagination">
                <ul className="justify-content-center">
                  <li className="active">
                    <Link>1</Link>
                  </li>
                  <li>
                    <Link>2</Link>
                  </li>
                  <li>
                    <Link>3</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <h3 className="sidebar-title">Search</h3>
                <div className="sidebar-item search-form">
                  <form action>
                    <input type="text" />
                    <button type="submit">
                      <i className="bi bi-search" />
                    </button>
                  </form>
                </div>
                <h3 className="sidebar-title">Categories</h3>
                <div className="sidebar-item categories">
                  <ul>
                    <li>
                      <Link>Software Development Tools </Link>
                    </li>
                    <li>
                      <Link>Choosing Right Software Tool </Link>
                    </li>
                    <li>
                      <Link>Web Development</Link>
                    </li>
                    <li>
                      <Link>Business Intelligence &amp; Anaytics</Link>
                    </li>
                    <li>
                      <Link>Full Stack Web development</Link>
                    </li>
                  </ul>
                </div>
                <h3 className="sidebar-title">Recent Posts</h3>
                <div className="sidebar-item recent-posts">
                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-1.jpg" alt="" />
                    <h4>
                      <Link>
                        A Comprehensive Guide to Types of Software Development
                        Tools
                      </Link>
                    </h4>
                    <time dateTime="2020-01-01">Jan 4, 2023</time>
                  </div>
                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-2.jpg" alt="" />
                    <h4>
                      <Link>
                        How to Choose the Perfect Tool for Software Development
                      </Link>
                    </h4>
                    <time dateTime="2020-01-01">Feb 12, 2023</time>
                  </div>
                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-3.jpg" alt="" />
                    <h4>
                      <Link>Mastering Full-Stack Web Development</Link>
                    </h4>
                    <time dateTime="2020-01-01">Mar 25, 2023</time>
                  </div>
                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-4.jpg" alt="" />
                    <h4>
                      <Link>
                        Business Intelligence and Analytics Best Practices
                      </Link>
                    </h4>
                    <time dateTime="2020-01-01">July 11, 2023</time>
                  </div>
                </div>
                <h3 className="sidebar-title">Tags</h3>
                <div className="sidebar-item tags">
                  <ul>
                    <li>
                      <Link>App</Link>
                    </li>
                    <li>
                      <Link>IT</Link>
                    </li>
                    <li>
                      <Link>Business Intelligence</Link>
                    </li>
                    <li>
                      <Link>Analytics</Link>
                    </li>
                    <li>
                      <Link>Cloud</Link>
                    </li>
                    <li>
                      <Link>Software Integrations</Link>
                    </li>
                    <li>
                      <Link>Software Tools</Link>
                    </li>
                    <li>
                      <Link>Version Control</Link>
                    </li>
                    <li>
                      <Link>Software Optimization</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Index;
