import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function Technology() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Industries</li>
              <li>Technology</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left, rgba(0,0, 0, 0.7),rgba(0, 0, 0, 0.7)),url(${process.env.PUBLIC_URL}/assets/img/industries/technology.jpg)`,
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Technology
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                We are at the forefront of driving technological advancements
                through cutting-edge software solutions. Our passion for
                innovation, combined with our expertise in various domains,
                positions us as a key player in shaping the future of
                technology.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Technology Development &amp; Innovation</a>
                </h4>
                <p className="description">
                  BINLYTIX, where innovation thrives &amp; technology evolves.
                  We are your dedicated partner in driving technological
                  advancements, fostering innovation &amp; creating solutions
                  that shape the future.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>IT Services &amp; Support</a>
                </h4>
                <p className="description">
                  BINLYTIX is your trusted partner in delivering top-tier IT
                  services &amp; support. We specialize in providing
                  comprehensive solutions that empower businesses to optimize
                  operations &amp; enhance security .
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Data Analytics &amp; Business Intelligence</a>
                </h4>
                <p className="description">
                  We harness the power of data to transform your business. Our
                  advanced data analytics &amp; business intelligence solutions
                  empower organizations to make informed decisions &amp; achieve
                  strategic goals.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>Internet of Things (IoT)</a>
                </h4>
                <p className="description">
                  We develop IoT devices, platforms &amp; solutions which helps
                  to connect various devices and systems, creating a network of
                  interconnected smart devices that improve automation .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                At BINLYTIX, we embrace the transformative power of software in
                shaping industries and driving progress. Here's how we
                contribute to the dynamic world of technology
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bi bi-check" />
                  Custom Software Development
                </div>
                <p>
                  We specialize in creating bespoke software solutions that
                  cater to the unique needs of businesses across industries.
                  From startups to enterprises, our software empowers clients to
                  leverage technology for growth and efficiency.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Digital Transformation
                </div>
                <p>
                  We play a crucial role in guiding businesses through digital
                  transformation journeys. Our solutions enable organizations to
                  modernize processes, enhance customer experiences, and stay
                  ahead in a digital-first world.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Emerging Technologies
                </div>
                <p>
                  We harness emerging technologies like AI, machine learning,
                  blockchain, and IoT to create innovative solutions that solve
                  complex challenges and open up new possibilities for
                  businesses.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Software Consultation
                </div>
                <p>
                  Our experts provide strategic consultation to help businesses
                  identify technology gaps, opportunities, and the best software
                  solutions to achieve their goals.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Data Analytics and Insights
                </div>
                <p>
                  We empower businesses to make data-driven decisions by
                  developing analytics solutions that extract valuable insights
                  from large datasets, enabling better understanding and
                  strategic planning.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Cloud Solutions
                </div>
                <p>
                  Our cloud-based solutions enhance scalability, agility, and
                  cost-effectiveness for businesses by utilizing the power of
                  cloud technology.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Security and Compliance
                </div>
                <p>
                  We develop software solutions that prioritize security,
                  ensuring businesses are protected against cyber threats and
                  compliant with industry regulations.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Innovative Applications
                </div>
                <p>
                  From mobile apps to web applications, we create user-centric
                  software that improves engagement, communication, and overall
                  experiences.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default Technology;
