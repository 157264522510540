import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layouts";
import HomePage from "./Layouts/HomePage";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import SoftwareDevelopment from "./components/services/SoftwareDevelopment";
import BusinessAnalytics from "./components/services/BusinessAnalytics";
import SoftwareIntegrations from "./components/services/SoftwareIntegrations";
import CloudServices from "./components/services/CloudServices";
import Healthcare from "./components/industries/Healthcare";
import Retail from "./components/industries/Retail";
import Telecom from "./components/industries/Telecom";
import Technology from "./components/industries/Technology";
import Pharma from "./components/industries/Pharma";
import Finance from "./components/industries/Finance";
import RealEstate from "./components/industries/RealEstate";
import Travel from "./components/industries/Travel";
import Medicorm from "./components/products/Medicorm";
import Clivois from "./components/products/Clivois";
import Clinatix from "./components/products/Clinatix";
import CFS from "./components/products/CFS";
import Blog from "./components/Blog";
import Analytics from "./components/Blog/Analytics";
import FullStack from "./components/Blog/FullStack";
import PerfectTool from "./components/Blog/PerfectTool";
import SoftwareTools from "./components/Blog/SoftwareTools";
import Careers from "./components/Careers";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import NotFound from "./components/NotFound/NotFound";
function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      mirror: false,
      once: true,
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="services">
            <Route
              path="software-development-and-maintenance"
              element={<SoftwareDevelopment />}
            />
            <Route
              path="business-intelligence-and-analytics"
              element={<BusinessAnalytics />}
            />
            <Route
              path="software-integrations"
              element={<SoftwareIntegrations />}
            />
            <Route path="cloud-services" element={<CloudServices />} />
          </Route>
          <Route path="industries">
            <Route path="healthcare" element={<Healthcare />} />
            <Route path="retail" element={<Retail />} />
            <Route path="telecom" element={<Telecom />} />
            <Route path="technology" element={<Technology />} />
            <Route path="pharma" element={<Pharma />} />
            <Route path="finance" element={<Finance />} />
            <Route path="real-estate" element={<RealEstate />} />
            <Route path="tour-and-travel" element={<Travel />} />
          </Route>
          <Route path="products">
            <Route index element={<Blog />} />
            <Route path="medicorm" element={<Medicorm />} />
            <Route path="cfs" element={<CFS />} />
            <Route path="clivois" element={<Clivois />} />
            <Route path="clinatix" element={<Clinatix />} />
          </Route>
          <Route />
          <Route path="blog">
            <Route index element={<Blog />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="full-stack" element={<FullStack />} />
            <Route path="perfect-tool" element={<PerfectTool />} />
            <Route path="software-tools" element={<SoftwareTools />} />
          </Route>
          <Route path="careers" element={<Careers />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route exact path="contact-us" element={<ContactUs />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
