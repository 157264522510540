import { useEffect } from "react";
import OurClients from "../homepage/OurClients";
import Testimonials from "../homepage/Testimonials";
import PureCounter from "@srexi/purecounterjs";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import ContactUs from "../../Layouts/ContactUs";
import { Link } from "react-router-dom";

function AboutUs() {
  useEffect(() => {
    new PureCounter();
  }, []);
  useEffect(() => {
    new Swiper(".testimonials-carousel", {
      speed: 1000,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      slidesPerView: "3",
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
    });
  }, []);
  return (
    <>
      <section className="breadcrumbs" style={{ margin: 0 }}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>About Us</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>About Us</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "90vh",
          backgroundImage:
            "linear-gradient(to left,rgba(0,0,0, 0.7),rgba(0, 0, 0, 0.7)),url(assets/img/about-us.jpg)",
          backgroundPosition: "center",
        }}
        className="about-us-hero d-flex justify-content-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Welcome to <span>BINLYTIX</span>
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                We understand that every business has unique needs, which is why
                we offer customized solutions to help our clients achieve their
                goals. Our team of experienced professionals is committed to
                delivering high-quality solutions that are tailored to meet the
                specific requirements of our clients.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="facts section-bg">
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="row counters d-flex align-items-center">
            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 text-center">
              <img
                alt=""
                src="./assets/img/iso_1.svg"
                width="120px"
                height="120px"
              />
            </div>
            <div
              className="col-lg-2 col-md-2 col-sm-6 col-xs-6 text-center"
              style={{ transform: "scale(150%)" }}
            >
              <img
                alt=""
                src="./assets/img/iso_2.svg"
                width="120px"
                height="120px"
              />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 text-center">
              <span
                data-purecounter-start={0}
                data-purecounter-end={232}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p style={{ margin: 0 }}>Clients</p>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 text-center">
              <span
                data-purecounter-start={0}
                data-purecounter-end={521}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Projects</p>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 text-center">
              <span
                data-purecounter-start={0}
                data-purecounter-end={1463}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Hours Of Support</p>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 text-center">
              <span
                data-purecounter-start={0}
                data-purecounter-end={25}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Hard Workers</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about-us why-us section-bg about-us-page">
        <div className="container">
          <div className="row" data-aos="fade-up" date-aos-delay={200}>
            <div className="col-lg-5 video-box">
              <img
                alt=""
                src="assets/icons/about-us-header/vision-mission.svg"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-7 content">
              <div className="icon-box">
                <div className="icon">
                  <img alt="" src="assets/icons/about-us-header/vision.svg" />
                </div>
                <div className="icon-box-content">
                  <h3 className="title">Our Vision</h3>
                  <p className="description">
                    <i className="bx bxs-quote-alt-left quote-icon-left" />
                    To be an organization of global standing for developing
                    modern technological world
                    <i className="bx bxs-quote-alt-right quote-icon-right" />
                  </p>
                </div>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <img alt="" src="assets/icons/about-us-header/mission.svg" />
                </div>
                <div className="icon-box-content">
                  <h3 className="title">Our Mission</h3>
                  <p className="description">
                    <i className="bx bxs-quote-alt-left quote-icon-left" />
                    To help all level of organization to boost their business,
                    and grow exponentially
                    <i className="bx bxs-quote-alt-right quote-icon-right" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="about-us core-values section-bg">
        <div className="home-core-values">
          <h3 className="title">Our Core Values</h3>
          <ul className="items">
            <li>
              <img alt="" src="assets/icons/about-us-header/trust.svg" />
              Trust
            </li>
            <li>
              <img alt="" src="assets/icons/about-us-header/innovative.svg" />
              Innovation
            </li>
            <li>
              <img alt="" src="assets/icons/about-us-header/respect.svg" />
              Respect
            </li>
            <li>
              <img alt="" src="assets/icons/about-us-header/inclusivity.svg" />
              Inclusivity
            </li>
            <li>
              <img alt="" src="assets/icons/about-us-header/passion.svg" />
              Passion
            </li>
          </ul>
        </div>
      </div>

      <section id="testimonials" className="testimonials">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Process We Follow</h2>
          </div>
          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    This step involves thorough analysis and understanding of
                    the user's needs, requirements, and existing challenges.
                    This stage is crucial for identifying the scope of the
                    project, defining objectives, and laying the foundation for
                    the development process.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>

                  <div>
                    <img
                      src="assets/icons/about-us/study.svg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Study</h3>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    Defining the problem involves precisely articulating the
                    requirements and objectives of the project. This stage
                    focuses on understanding the client's needs, gathering user
                    feedback, and setting clear, measurable goals. It lays the
                    groundwork for creating a solution that effectively
                    addresses the identified problem or opportunity.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>
                  <div>
                    <img
                      alt=""
                      src="assets/icons/about-us/define.svg"
                      className="testimonial-img"
                    />
                    <h3>Define</h3>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    The development of the solution is the phase where the
                    actual software is designed, built, and tested. Programmers
                    and developers work on coding, creating algorithms, and
                    implementing the features and functionalities as per the
                    defined requirements. Extensive testing and quality
                    assurance are carried out to ensure the solution meets the
                    desired standards and effectively solves the identified
                    problem.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>
                  <div>
                    <img
                      alt=""
                      src="assets/icons/about-us/develop.svg"
                      className="testimonial-img"
                    />
                    <h3>Develop</h3>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    The implementation of the solution is the phase where the
                    developed software is deployed and integrated into the
                    client's environment. This involves installing the software,
                    configuring it to work with existing systems, and ensuring a
                    smooth transition from the old system, if applicable. User
                    training and support are provided to facilitate adoption,
                    and ongoing monitoring and maintenance are conducted to
                    ensure the solution's effectiveness and reliability.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>
                  <div>
                    <img
                      alt=""
                      src="assets/icons/about-us/implement.svg"
                      className="testimonial-img"
                    />
                    <h3>Implement</h3>
                  </div>
                </div>
              </div>
              {/* End testimonial item */}

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    Delivery of the solution is the final phase where the
                    completed software is handed over to the client or
                    end-users. This involves packaging the product, providing
                    documentation, and assisting with the installation process,
                    if required.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>
                  <div>
                    <img
                      alt=""
                      src="assets/icons/about-us/delivery.svg"
                      className="testimonial-img"
                    />
                    <h3>Delivery</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-us-features" className="about" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Why BINLYTIX?</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-4 pt-lg-0">
              <p>
                At BINLYTIX, we're not just building code, we're crafting
                solutions that empower businesses and individuals to thrive in a
                digital world. Discover the reasons why partnering with us is a
                smart choice.
              </p>
              <ul>
                <li>
                  <i className="bi bi-check2-circle" />
                  We are Client-Focused Organizaton.
                </li>
                <li>
                  <i className="bi bi-check2-circle" />
                  We respond quickly to our Client Requirements.
                </li>
                <li>
                  <i className="bi bi-check2-circle" />
                  We provide both Custom-Managed and Integrated Services.
                </li>
                <li>
                  <i className="bi bi-check2-circle" />
                  We have Resources, Capabilities and Industry Awareness.
                </li>
                <li>
                  <i className="bi bi-check2-circle" /> We are a fast growing
                  Organization and we specialize in providing Cutting-Edge
                  Technology Solutions.
                </li>
                <li>
                  <i className="bi bi-check2-circle" />
                  We work for Customer Satisfaction &amp; Long-term Relations.
                </li>
                <li>
                  <i className="bi bi-check2-circle" />
                  We create Scalable Business Impact and help achieve Solutions
                  in timely manner.
                </li>
                <li>
                  <i className="bi bi-check2-circle" /> We assess Customer goals
                  and work according to it in a Holistic Approach.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="portfolio">
        <div className="container">
          <div className="section-title">
            <h2>Portfolio</h2>
          </div>
          <div className="row-wrap">
            <div className="row" data-aos="fade-up">
              <p className="col-lg-12">
                We at Binlytix offer end to end analytics for few clients like
                Oliva Skin and Hair clinic, Idea clinic and we are trying to
                cover patient diagnostic analytics of early problem detection.
              </p>
              <div className="col-md-5 d-flex align-items-center justify-cntent-center">
                <img
                  alt=""
                  src="assets/img/features-1.svg"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-7 pt-4">
                <h3>Clinic's Performance Dashboard</h3>
                <p className="fst-italic">
                  Integrated to the CRM giving us a real time count of the leads
                  against walk-ins with the lead source, sub source along with
                  doctor's performance
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check" />
                    <p>
                      An encompassing report with an interactive feature that
                      helps you talk to your data and extract reports with click
                      of a button.
                    </p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>
                      Daily lead reports have been automated saving close to 25%
                      of their productive time.
                    </p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>
                      Monthly reports like Lead analysis take 25% lesser time
                      than what it is used for each campaign.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-md-5 order-1 order-md-2 d-flex align-items-center justify-cntent-center">
                <img
                  alt=""
                  src="assets/img/features-2.svg"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-7 pt-5 order-2 order-md-1">
                <h3>Healthcare Analytics Dashbard</h3>
                <p className="fst-italic">
                  With the doctor's performance dashboard linked to the CRM and
                  the Guest walk-in sheet, managers can view the performance of
                  the agents in just one dashboard
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check" />
                    <p>Registration of new patients.</p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>Visit Analysis</p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>Waiting Time Analysis</p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>Doctor Fee Analysis</p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>Revenue Analysis</p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>Patient Doctor Statistics Report</p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>
                      MIS Reports like Operation scorecard, Hospital Outpatient
                      Summary, Daily finance summary report, Marketing leads
                      dashboard.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-md-5 d-flex align-items-center justify-cntent-center">
                <img
                  alt=""
                  src="assets/img/features-1.svg"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-7 pt-4">
                <h3>ROI Dashboard</h3>
                <p className="fst-italic">
                  This dashboard was highly required to determine the outcome of
                  an effort in Marketing, change in campaigns, new centre
                  requirements etc.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check" />
                    <p>
                      Real-time Reports : This has cut down the wait time (3-4
                      hrs) or delay of receiving reports prior to using BI.
                    </p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>
                      Ad-hoc reporting is faster because of the intuitive nature
                      of the dashboard making it easier to deliver reports and
                      in turn take decisions faster.
                    </p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>
                      Monthly Lead trend report. (Day-wise/Concern-wise/Clinic's
                      wise breakup)
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-md-5 order-1 order-md-2 d-flex align-items-center justify-cntent-center">
                <img
                  alt=""
                  src="assets/img/features-2.svg"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-7 pt-5 order-2 order-md-1">
                <h3>Appointments &amp; Doctor's Performance Dashboard</h3>
                <p className="fst-italic">
                  With the doctor's performance dashboard linked to the CRM and
                  the Guest walk-in sheet, managers can view the performance of
                  the agents in just one dashboard
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check" />
                    <p>
                      Patient Walk-in, concerns resolved and clinics Pharmacy
                      Stock details.
                    </p>
                  </li>
                  <li>
                    <i className="bi bi-check" />
                    <p>
                      Drill down capability from across years to a daily trend
                      can help you understand your business and makes you take
                      predictive decisions.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OurClients />
      <Testimonials />
      <ContactUs />
    </>
  );
}
export default AboutUs;
