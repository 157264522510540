import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function CFS() {
  return (
    <>
      <div>
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Products</li>
                <li>Customer-Follow-Up-System</li>
              </ol>
            </div>
          </div>
        </section>
        <section
          id="hero"
          style={{
            marginTop: "0",
            height: "80vh",
            backgroundImage: `linear-gradient(to left,rgba(0,0, 0, 0.7),rgba(0, 0, 0, 0.7)),url(${process.env.PUBLIC_URL}/assets/img/products/cfs.jpg)`,
          }}
          className="industries-hero d-flex justify-cntent-center align-items-center"
        >
          <div
            id="heroCarousel"
            className="container carousel carousel-fade"
            data-bs-ride="carousel"
            data-bs-interval={5000}
          >
            <div className="carousel-item active">
              <div className="carousel-container">
                <h2
                  className="animate__animated animate__fadeInDown"
                  style={{ color: "white" }}
                >
                  Customer Follow-Up System
                </h2>
                <p
                  className="animate__animated animate__fadeInUp"
                  style={{ color: "white" }}
                >
                  At BINLYTIX, we understand the significance of maintaining
                  strong relationships with our valued customers. Our Customer
                  Follow-Up System is designed to ensure that every interaction
                  with our clients is meaningful, timely, and tailored to their
                  needs.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="services industries-services">
          <div className="container">
            <div className="section-title">
              <h2>Our Services</h2>
            </div>
            <div className="row">
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
              >
                <div className="icon-box icon-box-pink">
                  <h4 className="title">
                    <a>Personalized Communication</a>
                  </h4>
                  <p className="description">
                    We believe in treating each customer as an individual with
                    unique preferences and requirements. Our follow-up system is
                    built around personalized communication that resonates with
                    the specific needs and interests of our clients.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="icon-box icon-box-cyan">
                  <h4 className="title">
                    <a>Timely Response</a>
                  </h4>
                  <p className="description">
                    Time is of the essence in customer service. We are committed
                    to promptly responding to inquiries, addressing concerns,
                    and following up on previous interactions to ensure a
                    seamless experience for our customers.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="icon-box icon-box-green">
                  <h4 className="title">
                    <a>Proactive Engagement</a>
                  </h4>
                  <p className="description">
                    Anticipating the needs of our customers is key to building
                    lasting relationships. Our proactive engagement strategy
                    involves reaching out to customers before they even realize
                    they need assistance, offering support, and providing
                    valuable insights.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="icon-box icon-box-blue">
                  <h4 className="title">
                    <a>Automated Reminders</a>
                  </h4>
                  <p className="description">
                    Our system is equipped with automated reminders to ensure
                    that no follow-up task falls through the cracks. This
                    ensures that every customer query or concern is addressed in
                    a timely manner.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="services-our-specialization"
          className="services-our-specialization-integrations industries-role"
        >
          <div className="container">
            <div className="section-title">
              <h2>Our Role</h2>
            </div>
            <div
              className="row services-our-specialization-list"
              data-aos="fade-up"
            >
              <div className="col-lg-12">
                <p>
                  We believe that effective follow-up is essential for building
                  strong, long-lasting relationships with our customers. Our
                  Customer Follow-Up System is a testament to our dedication to
                  exceeding customer expectations and delivering exceptional
                  service at every touchpoint.
                </p>
              </div>
              <ul className="col-lg-12 services-our-specialization-list-2">
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Automated Reminders
                  </div>
                  <p>
                    Our system is equipped with automated reminders to ensure
                    that no follow-up task falls through the cracks. This
                    ensures that every customer query or concern is addressed in
                    a timely manner.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Feedback Collection
                  </div>
                  <p>
                    We value the feedback of our customers as it helps us
                    improve our products and services. Our follow-up system
                    includes mechanisms for collecting feedback at various
                    touchpoints, allowing us to continuously enhance the
                    customer experience.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Customizable Templates
                  </div>
                  <p>
                    To streamline communication and maintain consistency, our
                    system offers customizable templates for follow-up emails,
                    messages, and calls. These templates can be tailored to suit
                    the specific needs of different customer segments.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Integration with CRM
                  </div>
                  <p>
                    Our Customer Follow-Up System seamlessly integrates with our
                    Customer Relationship Management (CRM) software, allowing us
                    to keep track of customer interactions, preferences, and
                    purchase history in one centralized location.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Enhanced Customer Satisfaction
                  </div>
                  <p>
                    By staying engaged with our customers and addressing their
                    needs promptly, we strive to enhance overall satisfaction
                    and loyalty.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Increased Retention Rates
                  </div>
                  <p>
                    A proactive follow-up approach can significantly contribute
                    to customer retention rates, ensuring that our customers
                    continue to choose us for their needs.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Improved Brand Reputation
                  </div>
                  <p>
                    Consistent, personalized follow-up demonstrates our
                    commitment to customer care, helping to enhance our brand
                    reputation and differentiate us from competitors.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Actionable Insights
                  </div>
                  <p>
                    The feedback collected through our follow-up system provides
                    valuable insights into customer preferences, pain points,
                    and expectations, allowing us to make data-driven decisions
                    to better serve our customers.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <ContactUs />
    </>
  );
}
export default CFS;
