import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

function Navbar() {
  const location = useLocation();
  const currentLink = location.pathname.split("/")[1];
  const currentSubLink = location.pathname.split("/")[2];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [
    {
      isServicesMenuOpen,
      isProductsMenuOpen,
      isTechMenuOpen,
      isIndustriesMenuOpen,
      isTechExpertiseOpen,
      isTechDatabaseOpen,
      isTechTestingOpen,
    },
    setState,
  ] = useState({
    isServicesMenuOpen: false,
    isProductsMenuOpen: false,
    isTechMenuOpen: false,
    isIndustriesMenuOpen: false,
    isTechExpertiseOpen: false,
    isTechDatabaseOpen: false,
    isTechTestingOpen: false,
  });
  return (
    <>
      <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent"
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div className="logo">
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/binlytix.png`}
                alt="BINLYTIX logo"
              />
            </Link>
          </div>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link className={currentLink === "" && "active"} to="/">
                  Home
                </Link>
              </li>
              <li className="dropdown nav-services">
                <Link className={currentLink === "services" && "active"}>
                  <span>Services</span>
                </Link>
                <ul>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/nav-bar/software.svg`}
                    />
                    <Link
                      className={
                        currentSubLink ===
                          "software-development-and-maintenance" &&
                        "nav-menu-active"
                      }
                      to="/services/software-development-and-maintenance"
                    >
                      Software Development and Maintenance
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/nav-bar/analytics.svg`}
                    />
                    <Link
                      to="/services/business-intelligence-and-analytics"
                      className={
                        currentSubLink ===
                          "business-intelligence-and-analytics" &&
                        "nav-menu-active"
                      }
                    >
                      Business Intelligence and Analytics
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/nav-bar/integration.svg`}
                    />
                    <Link
                      to="/services/software-integrations"
                      className={
                        currentSubLink === "software-integrations" &&
                        "nav-menu-active"
                      }
                    >
                      Software Integrations
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/nav-bar/cloud.svg`}
                    />
                    <Link
                      to="/services/cloud-services"
                      className={
                        currentSubLink === "cloud-services" && "nav-menu-active"
                      }
                    >
                      Cloud Services
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown nav-products">
                <Link className={currentLink === "products" && "active"}>
                  <span>Products</span>
                </Link>
                <ul>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/nav-bar/e-health-record.svg`}
                    />
                    <div>
                      <Link
                        to="/products/medicorm"
                        className={
                          currentSubLink === "medicorm" && "nav-menu-active"
                        }
                      >
                        Medicorm
                      </Link>
                      <p>Electronic Health Record With ERP CRM</p>
                    </div>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/nav-bar/cfs.svg`}
                    />
                    <div>
                      <Link
                        to="products/cfs"
                        className={
                          currentSubLink === "cfs" && "nav-menu-active"
                        }
                      >
                        CFS
                      </Link>
                      <p>Customer Follow-Up System</p>
                    </div>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/nav-bar/call-center.svg`}
                    />
                    <div>
                      <Link
                        to="products/clivois"
                        className={
                          currentSubLink === "clivois" && "nav-menu-active"
                        }
                      >
                        Clivois
                      </Link>
                      <p>Inbound &amp; Outbound Call Centre Software</p>
                    </div>
                  </li>

                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/nav-bar/dashboard-report.svg`}
                    />
                    <div>
                      <Link
                        to="products/clinatix"
                        className={
                          currentSubLink === "clinatix" && "nav-menu-active"
                        }
                      >
                        Clinatix
                      </Link>
                      <p>Analytics, Reports &amp; Dashboard Software</p>
                    </div>
                  </li>
                </ul>
              </li>
              <li className="dropdown nav-technologies">
                <Link>Technologies</Link>
                <ul className="our-services">
                  <li>
                    <Link> Our Expertise</Link>
                    <ul className="dropdown-our-expertise">
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/power-bi.svg`}
                        />
                        <Link className="no-link">Power BI</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/tableau.svg`}
                        />
                        <Link className="no-link">Tableau</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/pegasystems.svg`}
                        />
                        <Link className="no-link">Pega Systems</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/snowflake.svg`}
                        />
                        <Link className="no-link">Snowflake</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/oracle-cloud.svg`}
                        />
                        <Link className="no-link">Oracle Cloud</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/microsoft-dynamics-365.svg`}
                        />
                        <Link className="no-link">Microsoft Dynamics</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/sap.svg`}
                        />
                        <Link className="no-link">SAP</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/power-apps.svg`}
                        />
                        <Link className="no-link">Power Apps</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/power-automate.svg`}
                        />
                        <Link className="no-link">Power Automate</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/power-virtual-agents.svg`}
                        />
                        <Link className="no-link">Power Virtual Agents</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/java.svg`}
                        />
                        <Link className="no-link">Java</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/react.svg`}
                        />
                        <Link className="no-link">React</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/angular.svg`}
                        />
                        <Link className="no-link">Angular</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/php.svg`}
                        />
                        <Link className="no-link">php</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/c++.svg`}
                        />
                        <Link className="no-link">c++</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/python.svg`}
                        />
                        <Link className="no-link">Python</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/javascript.svg`}
                        />
                        <Link className="no-link">Javascript</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/net.svg`}
                        />
                        <Link className="no-link">.NET</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/laravel.svg`}
                        />
                        <Link className="no-link">Laravel</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/adobe.svg`}
                        />
                        <Link className="no-link">Adobe</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>Database Management Solutions</Link>
                    <ul className="dropdown-database-solutions">
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/mysql.svg`}
                        />
                        <Link className="no-link">MySQL</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/sql-server.svg`}
                        />
                        <Link className="no-link">SQL Server</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/mongodb.svg`}
                        />
                        <Link className="no-link">MongoDB</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/postgres.svg`}
                        />
                        <Link className="no-link">Postgres</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/oracle.svg`}
                        />
                        <Link className="no-link">Oracle DB</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>Testing Hands-On</Link>
                    <ul className="dropdown-testing">
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/selenium.svg`}
                        />
                        <Link className="no-link">Selenium</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/katalon.svg`}
                        />
                        <Link className="no-link">Katalon</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/micro-focus.svg`}
                        />
                        <Link className="no-link">Micro Focus</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/appium.svg`}
                        />
                        <Link className="no-link">Appium</Link>
                      </li>
                      <li>
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/assets/icons/technologies/qmetry.svg`}
                        />
                        <Link className="no-link">Q-METRY</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="dropdown nav-industries">
                <Link className={currentLink === "industries" && "active"}>
                  Industries
                </Link>
                <ul>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/industries/healthcare.svg`}
                    />
                    <Link
                      to="industries/healthcare"
                      className={
                        currentSubLink === "healthcare" && "nav-menu-active"
                      }
                    >
                      Healthcare
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/industries/retail.svg`}
                    />
                    <Link
                      to="industries/retail"
                      className={
                        currentSubLink === "retail" && "nav-menu-active"
                      }
                    >
                      Retail
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/industries/telecom.svg`}
                    />
                    <Link
                      to="industries/telecom"
                      className={
                        currentSubLink === "telecom" && "nav-menu-active"
                      }
                    >
                      Telecom
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/industries/technology.svg`}
                    />
                    <Link
                      to="industries/technology"
                      className={
                        currentSubLink === "technology" && "nav-menu-active"
                      }
                    >
                      Technology
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/industries/pharma.svg`}
                    />
                    <Link
                      className={
                        currentSubLink === "pharma" && "nav-menu-active"
                      }
                      to="industries/pharma"
                    >
                      Pharma
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/industries/finance.svg`}
                    />
                    <Link
                      to="industries/finance"
                      className={
                        currentSubLink === "finance" && "nav-menu-active"
                      }
                    >
                      Finance
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/industries/real-estate.svg`}
                    />
                    <Link
                      to="industries/real-estate"
                      className={
                        currentSubLink === "real-estate" && "nav-menu-active"
                      }
                    >
                      Real Estate
                    </Link>
                  </li>
                  <li>
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}/assets/icons/industries/travel.svg`}
                    />
                    <Link
                      to="industries/tour-and-travel"
                      className={
                        currentSubLink === "tour-and-travel" &&
                        "nav-menu-active"
                      }
                    >
                      Tour and Travel
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="blog" className={currentLink === "blog" && "active"}>
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="careers"
                  className={currentLink === "careers" && "active"}
                >
                  Careers
                </Link>
              </li>
              <li>
                <Link
                  to="about-us"
                  className={currentLink === "about-us" && "active"}
                >
                  About Us
                </Link>
              </li>
            </ul>
            <Link className="nav-contact-us" to="contact-us">
              Contact Us
            </Link>
          </nav>
          <button
            className="btn btn-primary"
            // data-bs-toggle="modal"
            // data-bs-target="#nav-mobile"
            onClick={handleShow}
          >
            <i className="bi bi-list" />
          </button>
        </div>
      </header>

      <Modal id="nav-mobile-modal" show={show} onHide={handleClose}>
        <div id="nav-mobile">
          <div>
            <div
              class="modal-content"
              onClick={(e) => {
                if (!e.target.closest(".menu")) {
                  setState((val) => ({
                    ...val,
                    isServicesMenuOpen: false,
                    isProductsMenuOpen: false,
                    isTechMenuOpen: false,
                    isIndustriesMenuOpen: false,
                  }));
                }
              }}
            >
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
              <ul className="menu">
                <li>
                  <Link>Home</Link>
                </li>
                <li class="dropdown nav-services">
                  <Link
                    onClick={() => {
                      setState((val) => ({
                        ...val,
                        isServicesMenuOpen: !val.isServicesMenuOpen,
                        isProductsMenuOpen: false,
                        isTechMenuOpen: false,
                        isIndustriesMenuOpen: false,
                      }));
                    }}
                    className={isServicesMenuOpen && "nav-menu-active"}
                  >
                    <span>Services</span>
                  </Link>

                  <ul
                    className={`${
                      isServicesMenuOpen && "nav-mobile-menu-active"
                    }`}
                  >
                    <li>
                      <Link
                        className={
                          currentSubLink ===
                            "software-development-and-maintenance" &&
                          "nav-menu-active"
                        }
                        to="services/software-development-and-maintenance"
                        onClick={handleClose}
                      >
                        Software Development and Maintenance
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentSubLink ===
                            "business-intelligence-and-analytics" &&
                          "nav-menu-active"
                        }
                        to="services/business-intelligence-and-analytics"
                        onClick={handleClose}
                      >
                        Business Intelligence and Analytics
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          currentSubLink === "software-integrations" &&
                          "nav-menu-active"
                        }
                        to="services/software-integrations"
                        onClick={handleClose}
                      >
                        Software Integrations
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/cloud-services"
                        className={
                          currentSubLink === "cloud-services" &&
                          "nav-menu-active"
                        }
                        onClick={handleClose}
                      >
                        Cloud Services
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown nav-products">
                  <Link
                    onClick={() => {
                      setState((val) => ({
                        ...val,
                        isProductsMenuOpen: !val.isProductsMenuOpen,
                        isServicesMenuOpen: false,
                        isTechMenuOpen: false,
                        isIndustriesMenuOpen: false,
                      }));
                    }}
                    className={isProductsMenuOpen && "nav-menu-active"}
                  >
                    <span>Products</span>
                  </Link>
                  <ul
                    className={`${
                      isProductsMenuOpen && "nav-mobile-menu-active"
                    }`}
                  >
                    <li>
                      <div>
                        <Link
                          to="products/medicorm"
                          onClick={handleClose}
                          className={
                            currentSubLink === "medicorm" && "nav-menu-active"
                          }
                        >
                          Medicorm
                        </Link>
                        <p>Electronic Health Record With ERP CRM</p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Link
                          to="products/cfs"
                          onClick={handleClose}
                          className={
                            currentSubLink === "cfs" && "nav-menu-active"
                          }
                        >
                          CFS
                        </Link>
                        <p>Customer Follow-Up System</p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Link
                          to="products/clivois"
                          onClick={handleClose}
                          className={
                            currentSubLink === "clivois" && "nav-menu-active"
                          }
                        >
                          Clivois
                        </Link>
                        <p>Inbound & Outbound Call Centre Software</p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Link
                          to="products/clinatix"
                          onClick={handleClose}
                          className={
                            currentSubLink === "clinatix" && "nav-menu-active"
                          }
                        >
                          Clinatix
                        </Link>
                        <p>Analytics, Reports & Dashboard Software</p>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="dropdown nav-technologies">
                  <Link
                    className={isTechMenuOpen && "nav-menu-active"}
                    onClick={() => {
                      setState((val) => ({
                        ...val,
                        isTechMenuOpen: !val.isTechMenuOpen,
                        isServicesMenuOpen: false,
                        isProductsMenuOpen: false,
                        isIndustriesMenuOpen: false,
                      }));
                    }}
                  >
                    Technologies
                  </Link>
                  <ul
                    className={`our-services ${
                      isTechMenuOpen && "nav-mobile-menu-active"
                    }`}
                  >
                    <li className="dropdown">
                      <Link
                        className={isTechExpertiseOpen && "nav-menu-active"}
                        onClick={() => {
                          setState((val) => ({
                            ...val,
                            isTechExpertiseOpen: !val.isTechExpertiseOpen,
                            isTechDatabaseOpen: false,
                            isTechTestingOpen: false,
                          }));
                        }}
                      >
                        Our Expertise
                      </Link>
                      <ul
                        class={`dropdown-our-expertise ${
                          isTechExpertiseOpen && "nav-mobile-menu-active"
                        }`}
                      >
                        <li>
                          <Link>Power BI</Link>
                        </li>
                        <li>
                          <Link>Tableau</Link>
                        </li>
                        <li>
                          <Link>Pega Systems</Link>
                        </li>
                        <li>
                          <Link>Snowflake</Link>
                        </li>
                        <li>
                          <Link>Oracle Cloud</Link>
                        </li>
                        <li>
                          <Link>Microsoft Dynamics</Link>
                        </li>
                        <li>
                          <Link>SAP</Link>
                        </li>
                        <li>
                          <Link>Power Apps</Link>
                        </li>
                        <li>
                          <Link>Power Automate</Link>
                        </li>
                        <li>
                          <Link>Power Virtual Agents</Link>
                        </li>
                        <li>
                          <Link>Java</Link>
                        </li>
                        <li>
                          <Link>React</Link>
                        </li>
                        <li>
                          <Link>Angular</Link>
                        </li>
                        <li>
                          <Link>php</Link>
                        </li>
                        <li>
                          <Link>c++</Link>
                        </li>
                        <li>
                          <Link>Python</Link>
                        </li>
                        <li>
                          <Link>Javascript</Link>
                        </li>
                        <li>
                          <Link>.NET</Link>
                        </li>

                        <li>
                          <Link>Laravel</Link>
                        </li>
                        <li>
                          <Link>Adobe</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <Link
                        className={isTechDatabaseOpen && "nav-menu-active"}
                        onClick={() => {
                          setState((val) => ({
                            ...val,
                            isTechDatabaseOpen: !val.isTechDatabaseOpen,
                            isTechExpertiseOpen: false,
                            isTechTestingOpen: false,
                          }));
                        }}
                      >
                        Database Management Solutions
                      </Link>
                      <ul
                        className={`dropdown-database-solutions ${
                          isTechDatabaseOpen && "nav-mobile-menu-active"
                        }`}
                      >
                        <li>
                          <Link>MySQL</Link>
                        </li>
                        <li>
                          <Link>SQL Server</Link>
                        </li>
                        <li>
                          <Link>MongoDB</Link>
                        </li>
                        <li>
                          <Link>Postgres</Link>
                        </li>
                        <li>
                          <Link>Oracle DB</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <Link
                        className={isTechTestingOpen && "nav-menu-active"}
                        onClick={() => {
                          setState((val) => ({
                            ...val,
                            isTechTestingOpen: !val.isTechTestingOpen,
                            isTechExpertiseOpen: false,
                            isTechDatabaseOpen: false,
                          }));
                        }}
                      >
                        Testing Hands-On
                      </Link>
                      <ul
                        className={`dropdown-testing ${
                          isTechTestingOpen && "nav-mobile-menu-active"
                        }`}
                      >
                        <li>
                          <Link>Selenium</Link>
                        </li>

                        <li>
                          <Link>Katalon</Link>
                        </li>
                        <li>
                          <Link>Micro Focus</Link>
                        </li>
                        <li>
                          <Link>Appium</Link>
                        </li>
                        <li>
                          <Link>Q-METRY</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="dropdown nav-industries">
                  <Link
                    className={isIndustriesMenuOpen && "nav-menu-active"}
                    onClick={() => {
                      setState((val) => ({
                        ...val,
                        isIndustriesMenuOpen: !val.isIndustriesMenuOpen,
                        isServicesMenuOpen: false,
                        isProductsMenuOpen: false,
                        isTechMenuOpen: false,
                      }));
                    }}
                  >
                    Industries
                  </Link>
                  <ul
                    className={`${
                      isIndustriesMenuOpen && "nav-mobile-menu-active"
                    }`}
                  >
                    <li>
                      <Link
                        to="industries/healthcare"
                        className={
                          currentSubLink === "healthcare" && "nav-menu-active"
                        }
                        onClick={handleClose}
                      >
                        Healthcare
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="industries/retail"
                        onClick={handleClose}
                        className={
                          currentSubLink === "retail" && "nav-menu-active"
                        }
                      >
                        Retail
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="industries/telecom"
                        onClick={handleClose}
                        className={
                          currentSubLink === "telecom" && "nav-menu-active"
                        }
                      >
                        Telecom
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="industries/technology"
                        onClick={handleClose}
                        className={
                          currentSubLink === "technology" && "nav-menu-active"
                        }
                      >
                        Technology
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="industries/pharma"
                        onClick={handleClose}
                        className={
                          currentSubLink === "pharma" && "nav-menu-active"
                        }
                      >
                        Pharma
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="industries/finance"
                        onClick={handleClose}
                        className={
                          currentSubLink === "finance" && "nav-menu-active"
                        }
                      >
                        Finance
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="industries/real-estate"
                        className={
                          currentSubLink === "real-estate" && "nav-menu-active"
                        }
                        onClick={handleClose}
                      >
                        Real Estate
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="industries/tour-and-travel"
                        className={
                          currentSubLink === "tour-and-travel" &&
                          "nav-menu-active"
                        }
                        onClick={handleClose}
                      >
                        Tour and Travel
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="blog"
                    onClick={() => {
                      handleClose();
                      setState((val) => ({
                        ...val,
                        isServicesMenuOpen: false,
                        isProductsMenuOpen: false,
                        isTechMenuOpen: false,
                        isIndustriesMenuOpen: false,
                        isTechExpertiseOpen: false,
                        isTechDatabaseOpen: false,
                        isTechTestingOpen: false,
                      }));
                    }}
                    className={currentLink === "blog" && "nav-menu-active"}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="careers"
                    onClick={() => {
                      handleClose();
                      setState((val) => ({
                        ...val,
                        isServicesMenuOpen: false,
                        isProductsMenuOpen: false,
                        isTechMenuOpen: false,
                        isIndustriesMenuOpen: false,
                        isTechExpertiseOpen: false,
                        isTechDatabaseOpen: false,
                        isTechTestingOpen: false,
                      }));
                    }}
                    className={currentLink === "careers" && "nav-menu-active"}
                  >
                    Careers
                  </Link>
                </li>
                <li>
                  <Link
                    to="about-us"
                    onClick={() => {
                      handleClose();
                      setState((val) => ({
                        ...val,
                        isServicesMenuOpen: false,
                        isProductsMenuOpen: false,
                        isTechMenuOpen: false,
                        isIndustriesMenuOpen: false,
                        isTechExpertiseOpen: false,
                        isTechDatabaseOpen: false,
                        isTechTestingOpen: false,
                      }));
                    }}
                    className={currentLink === "about-us" && "nav-menu-active"}
                  >
                    About Us
                  </Link>
                </li>
              </ul>
              <div className="contact-btn-wrap">
                <Link
                  className="nav-contact-us"
                  to="contact-us"
                  onClick={handleClose}
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default Navbar;
