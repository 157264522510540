function WhyUs() {
  return (
    <section className="features">
      <div className="section-title">
        <h2>Why BINLYTIX?</h2>
      </div>
      <div
        className="container"
        data-aos="fade-up"
        date-aos-delay={200}
        style={{ backgroundColor: "#e6edee" }}
      >
        <p>
          At BINLYTIX, we're not just building code, we're crafting solutions
          that empower businesses and individuals to thrive in a digital world.
          Discover the reasons why partnering with us is a smart choice
        </p>
        <div className="row row-1" data-aos="fade-up">
          <div className="features-img-1 col-lg-6 col-md-12 d-flex align-items-center justify-cntent-center">
            <img
              src="assets/img/home-why-binlytix/software-crafting.svg"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-6 col-md-12 p-3 content">
            <div className="content-wrap">
              <div>
                <i className="bi bi-check" />
                <h3>Expertise in Cutting-Edge Technologies</h3>
              </div>
              <p>
                With a team of seasoned software engineers and tech enthusiasts,
                we stay ahead of the curve in the ever-evolving world of
                technology. Our deep expertise in cutting-edge technologies
                allows us to deliver solutions that are innovative, efficient,
                and future-proof.
              </p>
            </div>
            <div className="content-wrap">
              <div>
                <i className="bi bi-check" />
                <h3>Custom-Tailored Solutions</h3>
              </div>
              <p>
                We understand that every business is unique. That's why we take
                a personalized approach to software development. Our solutions
                are meticulously tailored to your specific needs, ensuring that
                you get the functionality you require without any unnecessary
                frills.
              </p>
            </div>
            <div className="content-wrap">
              <div>
                <i className="bi bi-check" />
                <h3>Seamless User Experience</h3>
              </div>
              <p>
                User-centric design is at the heart of everything we create. Our
                intuitive interfaces and smooth interactions provide your users
                with an exceptional experience, enhancing engagement and driving
                success.
              </p>
            </div>
          </div>
        </div>
        <div className="row row-2 d-flex" data-aos="fade-up">
          <div className="col-lg-6 col-md-12 p-3 content">
            <div className="content-wrap">
              <div>
                <i className="bi bi-check" />
                <h3>Scalability and Flexibility</h3>
              </div>
              <p>
                Our software solutions are designed with growth in mind. Whether
                you're a startup gearing up for expansion or an established
                enterprise seeking scalability, our systems are built to
                accommodate your evolving needs.
              </p>
            </div>
            <div className="content-wrap">
              <div>
                <i className="bi bi-check" />
                <h3>Timely Delivery, Every Time</h3>
              </div>
              <p>
                We understand that time is of the essence. Our proven project
                management methodologies ensure that your software is delivered
                on time and within budget, without compromising on quality.
              </p>
            </div>
            <div className="content-wrap">
              <div>
                <i className="bi bi-check" />
                <h3>Continuous Support and Maintenance</h3>
              </div>
              <p>
                Our commitment doesn't end with the launch. We provide ongoing
                support and maintenance to keep your software running smoothly.
                Our responsive team is always ready to assist and ensure that
                your technology remains up-to-date.
              </p>
            </div>
          </div>
          <div className="features-img-2 col-lg-6 col-md-12 d-flex align-items-center justify-cntent-center">
            <img
              src="assets/img/home-why-binlytix/scalable.svg"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
export default WhyUs;
