import { useEffect } from "react";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "boxicons";

function Testimonials() {
  useEffect(() => {
    new Swiper(".testimonials-slider", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      slidesPerView: "auto",
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },

        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });
  }, []);
  return (
    <>
      <section id="testimonials" className="testimonials">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Testimonials</h2>
          </div>
          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    It's been invaluable in organizing our client database,
                    addressing our pain points effectively. Following up with
                    clients, tracking potential customers, and prioritizing
                    sales tasks are now seamless. The real-time updates and
                    user-friendly interface enhance its effectiveness, making it
                    a highly valuable tool.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>

                  <div>
                    <img
                      src="assets/img/clients/oliva.jpg"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Oliva Skin and Hair Clinic</h3>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    We thank Binlytix Solutions for the wonderful job in helping
                    us develop our software program. Everyone was professional,
                    excellent and hard working. Thanks to them, we were able to
                    achieve our goal on time, and we look forward to continue
                    working with them in the future.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>
                  <div>
                    <img
                      src="assets/img/clients/idea-clinics.png"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>IDEA Clinics</h3>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    We're delighted with the collaboration on our web
                    application development with Binlytix & the development
                    team. Their professionalism and the quality of the solution
                    exceeded our expectations. The communication throughout the
                    project was excellent. We highly recommend Binlytix and look
                    forward to future projects with them.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>
                  <div>
                    <img
                      src="assets/img/clients/bradsol.svg"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Bradsol</h3>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    Binlytix delivered exceptional service with prompt
                    responsiveness and efficient troubleshooting. Their
                    professional and courteous approach, coupled with clear
                    explanations of complex issues, stood out. Consistently
                    delivering top-notch results, Binlytix has earned our full
                    confidence.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>
                  <div>
                    <img
                      src="assets/img/clients/lifecell.jpg"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Life Cell</h3>
                  </div>
                </div>
              </div>
              {/* End testimonial item */}

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    Binlytix significantly improved our business tracking and
                    team collaboration. Its advanced features surpass Excel,
                    offering superior user-friendliness in charting, reporting,
                    and sharing. I highly recommend Binlytix for its efficiency
                    and effectiveness in enhancing business processes.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>
                  <div>
                    <img
                      src="assets/img/clients/parking-rhino.png"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Parking Rhino</h3>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <box-icon
                      name="quote-alt-left"
                      type="solid"
                      color="#9cb6bc"
                      size="large"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                    I am really impressed by the quality of services I received
                    from Binlytix Solutions. They were right on schedule,
                    charged reasonable prices, were professional and courteous
                    in dealings, and delivered items well before time. I have
                    got a good web application and mobile application for my
                    products. My revenue has increased because of Binlytix and I
                    will definitely use their services again.
                    <box-icon
                      type="solid"
                      name="quote-alt-right"
                      color="#9cb6bc"
                      style={{ margin: "0 10px" }}
                    ></box-icon>
                  </p>
                  <div>
                    <img
                      src="assets/img/clients/code-experts.jpg"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Code Experts</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Testimonials;
