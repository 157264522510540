import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

function Index() {
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data, e) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      setLoading(true);
      const fetchResponse = await fetch(window["CONTACT_URL"], settings);
      const data = await fetchResponse.json();
      toast.success(data.message);
      reset();
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Contact</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Contact Us</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage:
            "linear-gradient(to left,rgba(0,0, 0, 0.7), rgba(0, 0, 0, 0.7)),url(assets/img/contact-us.jpg)",
          backgroundPosition: "0 20%",
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Contact Us
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Welcome to BINLYTIX, where innovation meets excellence! We are
                thrilled that you're interested in connecting with us. Whether
                you're seeking cutting-edge software solutions, expert
                consultation, or simply have questions about our services, we're
                here to provide you with top-notch support and assistance.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="contact"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration={500}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-box">
                    <i className="bx bx-map" />
                    <h3>Our Address</h3>
                    <p>
                      Binlytix Solutions Pvt. Ltd. <br />
                      Sri Sri Towers, 201/202, Road Number 1, KPHB Phase 1,
                      Kukatpally,
                      <br />
                      Hyderabad, Telangana-500072, India.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box info-box-email">
                    <i className="bx bx-envelope" />
                    <h3>Email Us</h3>
                    <p>
                      <a href="mailto:contact@binytix.com">
                        contact@binytix.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box info-box-phone">
                    <i className="bx bx-phone-call" />
                    <h3>Call Us</h3>
                    <p style={{ justifySelf: "start" }}>
                      <strong style={{ color: "grey" }}>Phone: </strong>
                      <a href="tel:+914035511051">(+91) 040 35511051</a>
                    </p>
                    <p style={{ justifySelf: "start" }}>
                      <strong style={{ color: "grey" }}>Mobile: </strong>
                      <a href="tel:+916305473076">+91 6305473076</a>
                    </p>
                    <p style={{ justifySelf: "start" }}>
                      <strong style={{ color: "grey" }}>For Queries: </strong>
                      <a href="tel:+914035510801"> (+91) 040 35510801</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <form
                id="mailForm"
                className="php-email-form contact-page"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group mt-3 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    {...register("name")}
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      {...register("email")}
                      className="form-control"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      {...register("phone")}
                      placeholder="Mobile"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    {...register("subject")}
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    {...register("message")}
                    rows={5}
                    placeholder="Message"
                    required
                  />
                </div>
                <div className="my-3">
                  {!loading && (
                    <button type="submit" disabled={loading}>
                      <span
                        style={{
                          display: "inline-block",
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                      >
                        Book a Demo
                      </span>
                    </button>
                  )}
                  <div
                    class={loading && `loader`}
                    style={{ display: "inline-block", marginTop: "24px" }}
                  ></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="map mt-2">
        <div className="container-fluid p-0">
          <iframe
            title="Location"
            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=binlytix solutions&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen
          />
        </div>
      </section>
    </>
  );
}
export default Index;
