import { useEffect } from "react";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

function OurClients() {
  useEffect(() => {
    new Swiper(".clients-carousel", {
      speed: 1000,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",

      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
    });
  }, []);
  return (
    <>
      <section className="our-clients" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Our Clients</h2>
          </div>
          <div
            className="clients-carousel swiper"
            style={{ padding: "32px 0 0 0 " }}
          >
            <div class="swiper-wrapper">
              <div className="swiper-slide">
                <div className="card">
                  <div className="card-img-grey">
                    <img src="assets/img/clients/oliva.jpg" alt="oliva logo" />
                  </div>
                  <div className="card-img-real">
                    <img src="assets/img/clients/oliva.jpg" alt="oliva logo" />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="card">
                  <div className="card-img-grey">
                    <img
                      src="assets/img/clients/idea-clinics.png"
                      alt="idea clinics logo"
                    />
                  </div>
                  <div className="card-img-real">
                    <img
                      src="assets/img/clients/idea-clinics.png"
                      alt="idea clinics logo"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="card">
                  <div className="card-img-grey">
                    <img
                      src="assets/img/clients/lifecell.jpg"
                      alt="life cell logo"
                    />
                  </div>
                  <div className="card-img-real">
                    <img
                      src="assets/img/clients/lifecell.jpg"
                      alt="life cell logo"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="card">
                  <div className="card-img-grey">
                    <img
                      src="assets/img/clients/bradsol.svg"
                      alt="bradsol logo"
                    />
                  </div>
                  <div className="card-img-real">
                    <img
                      src="assets/img/clients/bradsol.svg"
                      alt="bradsol logo"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="card">
                  <div className="card-img-grey">
                    <img
                      src="assets/img/clients/code-experts.jpg"
                      alt="code experts logo"
                    />
                  </div>
                  <div className="card-img-real">
                    <img
                      src="assets/img/clients/code-experts.jpg"
                      alt="code experts logo"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="card">
                  <div className="card-img-grey">
                    <img
                      src="assets/img/clients/parking-rhino.png"
                      alt="parking rhino logo"
                    />
                  </div>
                  <div className="card-img-real">
                    <img
                      src="assets/img/clients/parking-rhino.png"
                      alt="parking rhino logo"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="card">
                  <div className="card-img-grey">
                    <img
                      src="assets/img/clients/safe-software.jpg"
                      alt="parking rhino logo"
                    />
                  </div>
                  <div className="card-img-real">
                    <img
                      src="assets/img/clients/safe-software.jpg"
                      alt="parking rhino logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </>
  );
}
export default OurClients;
