import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";

function SocialFloat() {
  const [{ isContactFormOpen }, setState] = useState({
    isContactFormOpen: false,
  });
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data, e) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      setLoading(true);
      const fetchResponse = await fetch(window["CONTACT_URL"], settings);
      const data = await fetchResponse.json();
      toast.success(data.message);
      reset();
      setState((val) => ({ ...val, isContactFormOpen: false }));
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".contact-us-form")) {
        setState((val) => ({ ...val, isContactFormOpen: false }));
      }
    });
    return document.removeEventListener("click", (e) => {
      if (!e.target.closest(".contact-us-form")) {
        setState((val) => ({ ...val, isContactFormOpen: false }));
      }
    });
  }, []);
  return (
    <aside className="social-media-links">
      <div className="contact-us-form">
        <div
          className="contact-us-img"
          onMouseOver={() =>
            setState((val) => ({ ...val, isContactFormOpen: true }))
          }
        >
          <p>Contact-Us</p>
          <img
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/icons/social-media/envelope.png`}
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`php-email-form form ${isContactFormOpen && "is-active"}`}
        >
          <div className="form-group mt-3 mb-3">
            <input
              type="text"
              className="form-control"
              {...register("name")}
              placeholder="Name"
              required
            />
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <input
                type="text"
                {...register("email")}
                className="form-control"
                placeholder="Email"
                required
              />
            </div>
            <div className="col-md-6 form-group mt-3 mt-md-0">
              <input
                type="text"
                className="form-control"
                {...register("phone")}
                placeholder="Mobile Number"
                required
              />
            </div>
          </div>
          <div className="form-group mt-3">
            <input
              type="text"
              className="form-control"
              {...register("subject")}
              placeholder="Subject"
              required
            />
          </div>
          <div className="form-group mt-3">
            <textarea
              className="form-control"
              rows={7}
              {...register("message")}
              placeholder="Message"
              required
            />
          </div>
          <div className="my-3" />
          <div className="text-center mt-3">
            {!loading && (
              <button type="submit" disabled={loading}>
                <span
                  style={{
                    display: "inline-block",
                    fontSize: "600",
                  }}
                >
                  Submit
                </span>
              </button>
            )}
            <div
              class={loading && `loader`}
              style={{ display: "inline-block", marginTop: "20px" }}
            ></div>
          </div>
        </form>
      </div>
      <div className="social-media">
        <div className="wrap">
          <div className="content-wrap linkedin">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/icons/social-media/linkedin.svg`}
            />
            <a href="https://www.linkedin.com/company/binlytix-solutions/">
              Linked In
            </a>
          </div>
          <div className="content-wrap twitter">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/icons/social-media/twitter.svg`}
            />
            <a href="https://twitter.com/binlytix284438">Twitter</a>
          </div>
          <div className="content-wrap facebook">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/icons/social-media/facebook.svg`}
            />
            <a href="https://www.facebook.com/profile.php?id=61553498813696">
              Facebook
            </a>
          </div>
          <div className="content-wrap instagram">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/icons/social-media/instagram.svg`}
            />
            <a href="https://www.instagram.com/binlytixdigital/">Instagram</a>
          </div>
          <div className="content-wrap youtube">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/icons/social-media/youtube.svg`}
            />
            <a href="https://www.youtube.com/channel/UCS52ExvvbtrR2-a-qpoMUTw">
              Youtube
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
}
export default SocialFloat;
