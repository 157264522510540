import Hero from "../components/homepage/Hero";
import Stats from "../components/homepage/Stats";
import AboutUs from "../components/homepage/AboutUs";
import Services from "../components/homepage/Services";
import WhyUs from "../components/homepage/WhyUs";
import Industries from "../components/homepage/Industries";
import OurClients from "../components/homepage/OurClients";
import Testimonials from "../components/homepage/Testimonials";
import ContactUs from "./ContactUs";

function HomePage() {
  return (
    <>
      <Hero />
      <Stats />
      <AboutUs />
      <Services />
      <WhyUs />
      <Industries />
      <OurClients />
      <Testimonials />
      <ContactUs />
    </>
  );
}
export default HomePage;
