import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function RealEstate() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Industries</li>
              <li>Retail</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left, rgba(0,0, 0, 0.7),rgba(0, 0, 0, 0.7)),url(${process.env.PUBLIC_URL}/assets/img/industries/real_estate.jpg)`,
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Real Estate
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Welcome to BINLYTIX, a trailblazer in revolutionizing the real
                estate industry through cutting-edge software solutions. Our
                passion for innovation, combined with our deep understanding of
                the real estate landscape, positions us as a key player in
                shaping the future of property management, sales, and
                investment.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Real Estate Listings &amp; CRM</a>
                </h4>
                <p className="description">
                  We specialize in providing cutting-edge listings &amp;
                  customer relationship management solutions that empower real
                  estate professionals to manage listings effectively, enhance
                  client relationships.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>Property Valuation &amp; Appraisals</a>
                </h4>
                <p className="description">
                  At BINLYTIX, We offer advanced software solutions that
                  revolutionize real estate professionals to accurately assess
                  property values, streamline appraisals &amp; make informed
                  decisions.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Virtual Tours &amp; Visualization</a>
                </h4>
                <p className="description">
                  We offer state-of-the-art virtual tours &amp; visualization
                  solutions that empower real estate professionals to showcase
                  properties like never before, captivating clients &amp;
                  driving success.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>Investment Analytics &amp; Decision-Making </a>
                </h4>
                <p className="description">
                  BINLYTIX, where investment analytics meets strategic
                  decision-making. We provide advanced software solutions that
                  enhances investors &amp; financial professionals to analyze
                  market trends, assess risks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                At BINLYTIX, we recognize the pivotal role of software in
                modernizing the real estate sector. Here's how we contribute to
                the growth and transformation of the industry
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bi bi-check" />
                  Property Management Solutions
                </div>
                <p>
                  We develop software that empowers property managers to
                  efficiently oversee and maintain their real estate assets. Our
                  solutions encompass tenant communication, maintenance
                  tracking, and lease management, streamlining operations.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Real Estate Listings and Costomer Relationship Management(CRM)
                </div>
                <p>
                  Our software solutions create user-friendly platforms for real
                  estate agents and agencies to showcase properties, manage
                  leads, and nurture client relationships, ultimately boosting
                  sales and conversions.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Investment Analytics &amp; Decision-Making
                </div>
                <p>
                  We provide data analytics tools that help real estate
                  investors analyze market trends, property performance, and
                  investment opportunities, enabling informed decisions for
                  optimal returns.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Virtual Tours &amp; Visualization:
                </div>
                <p>
                  Our virtual tour and visualization software offer immersive
                  experiences for buyers and renters, allowing them to explore
                  properties from the comfort of their screens, driving
                  engagement and accelerating sales.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Property Valuation &amp; Appraisals:
                </div>
                <p>
                  We offer software that aids appraisers and real estate
                  professionals in accurately assessing property values,
                  leveraging data-driven insights for more precise valuations.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Smart Building Management
                </div>
                <p>
                  Our solutions integrate IoT technology to enable remote
                  monitoring and control of building systems, enhancing energy
                  efficiency, security, and overall operational management.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Real Estate Crowdfunding Platforms
                </div>
                <p>
                  We facilitate real estate crowdfunding platforms that connect
                  investors with property developers, making property
                  investments more accessible and diversified.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default RealEstate;
