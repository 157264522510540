import { useEffect } from "react";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import ContactUs from "../../Layouts/ContactUs";
import { Link } from "react-router-dom";

function BusinessAnalytics() {
  useEffect(() => {
    new Swiper(".tech-carousel", {
      speed: 1000,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",

      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
    });
  }, []);
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Services</li>
              <li>Business Intelligence & Analytics</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero-services d-flex justify-cntent-center align-items-center"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(
            to left,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7)
          ),
          url(${process.env.PUBLIC_URL}/assets/img/business-intelligence/business_intelligence.jpg)`,
          backgroundPosition: "0 20%",
        }}
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: "600",
                }}
                className="animate__animated animate__fadeInDown"
              >
                Business Intelligence & Analytics
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Analyze, visualize, and make informed decisions with business
                intelligence and analytics. Instant visibility on business
                performance and for better business outcomes.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="featured-services" className="featured-services">
        <div className="container">
          <div className="col-lg-12 section-title">
            <h2>Analytics Solutions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom">
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/big-data.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">Big Data Analytics</Link>
                </h4>
                <p>
                  At BINLYTIX, we transform raw data into actionable insights.
                  Our cutting-edge big data analytics solutions empower
                  businesses to make informed decisions, discover patterns &
                  unlock the full potential of their data.
                </p>
              </div>
            </div>
            {/* End Service Item */}
            <div
              className="col-xl-4 col-md-6 d-flex"
              data-aos="zoom"
              data-aos-delay={200}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/dashboard.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">BI Dashboards</Link>
                </h4>
                <p>
                  We redefine how businesses visualize and analyze their data.
                  Our Business Intelligence Dashboards empower organizations to
                  make informed decisions through intuitive, real-time data
                  insights.
                </p>
              </div>
            </div>
            {/* End Service Item */}
            <div
              className="col-xl-4 col-md-6 d-flex"
              data-aos="zoom"
              data-aos-delay={400}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/customer-analytics.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">Customer Analytics</Link>
                </h4>
                <p>
                  Binlytix is your partner in unlocking the power of customer
                  data. Our Customer Analytics solutions go beyond numbers,
                  providing in-depth insights that drive personalized customer
                  experiences and boost your business growth.
                </p>
              </div>
            </div>
            {/* End Service Item */}
            <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom">
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/marketing.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">Marketing Analytics</Link>
                </h4>
                <p>
                  At BINLYTIX, we redefine marketing success through data-driven
                  insights. Our Marketing Analytics solutions empower businesses
                  to optimize campaigns, enhance customer engagement, and
                  achieve measurable ROI.
                </p>
              </div>
            </div>
            {/* End Service Item */}
            <div
              className="col-xl-4 col-md-6 d-flex"
              data-aos="zoom"
              data-aos-delay={200}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/research.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">Market Research</Link>
                </h4>
                <p>
                  We empower businesses with comprehensive market insights. Our
                  Market Research services go beyond data, providing you with
                  the knowledge to make informed decisions and stay ahead in
                  your industry.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-6 d-flex"
              data-aos="zoom"
              data-aos-delay={400}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/data-management.svg`}
                    alt=""
                  />
                </div>
                <h4>
                  <Link className="stretched-link">
                    Enterprise Data Management
                  </Link>
                </h4>
                <p>
                  Our Enterprise Data Management solutions empower organizations
                  to harness the full potential of their data, ensuring
                  accuracy, security, and strategic use across the entire data
                  lifecycle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-12 section-title">
              <h2>Our Approach</h2>
            </div>
          </div>
        </div>
        <div className="container" data-aos="fade-up">
          <ul className="nav nav-tabs row gy-4 d-flex">
            <li className="nav-item col-6 col-md-4 col-lg-3">
              <a
                className="nav-link active show"
                data-bs-toggle="tab"
                data-bs-target="#tab-1"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/descriptive.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4 style={{ textAlign: "center" }}>Descriptive Analytics</h4>
              </a>
            </li>

            {/* End Tab 2 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-3">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-2"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/diagnostic.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4 style={{ textAlign: "center" }}>Diagnostic Analytics</h4>
              </a>
            </li>
            {/* End Tab 3 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-3">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-3"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/predictive.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4 style={{ textAlign: "center" }}>Predictive Analytics</h4>
              </a>
            </li>
            {/* End Tab 4 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-3">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-4"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/business-analytics/prescriptive.svg`}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover" }}
                />
                <h4 style={{ textAlign: "center" }}>Prescriptive Analytics</h4>
              </a>
            </li>
            {/* End Tab 5 Nav */}
          </ul>
          <div className="tab-content">
            <div className="tab-pane active show" id="tab-1">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-12 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Descriptive Analytics</h3>
                  <p className="fst-italic">
                    Descriptive Analytics is the art of storytelling with data,
                    unraveling the narrative hidden within your historical
                    datasets. It involves summarizing, visualizing, and
                    interpreting data to answer essential questions about your
                    business's past activities and performance.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Data Aggregation and Summarization: </strong>We
                      meticulously aggregate and summarize your historical data,
                      providing a concise overview of your business activities.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Visual Insights: </strong>
                      Our visualizations breathe life into your data, offering
                      charts, graphs, and dashboards that turn complex
                      information into easily digestible insights.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>
                        Key Performance Indicator (KPI) Analysis:{" "}
                      </strong>
                      . Dive deep into your KPIs. We dissect and analyze the
                      metrics that matter most to your business, providing
                      clarity on past successes and areas for improvement.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Historical Trend Identification: </strong>
                      Explore trends and patterns that have shaped your
                      business. Our Descriptive Analytics reveals historical
                      events, enabling you to learn from the past and strategize
                      for the future.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* End Tab Content 1 */}
            <div className="tab-pane" id="tab-2">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-12 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Diagnostic Analytics</h3>
                  <p className="fst-italic">
                    Dive into the intricate details of your operations with
                    Diagnostic Analytics — a powerful tool that not only
                    uncovers the 'what' but intricately dissects the 'why'
                    behind your business dynamics.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>
                        Deeper Insights with Root Cause Analysis:{" "}
                      </strong>
                      Move beyond surface-level observations. Our Diagnostic
                      Analytics conducts thorough root cause analyses,
                      unraveling the underlying factors that shape your business
                      outcomes.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>
                        Pattern Recognition for Strategic Decision-Making:
                      </strong>
                      Recognize patterns influencing your business trajectory.
                      Whether it's customer behaviors, market trends, or
                      internal processes, we reveal the patterns that impact
                      your results and empower you to make strategic decisions.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>
                        Comparative Analysis for Comprehensive Understanding:
                      </strong>
                      Understand how different variables interact. Our analytics
                      conduct comparative analyses, pinpointing relationships
                      and dependencies within your data, ensuring a
                      comprehensive understanding of your business landscape.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* End Tab Content 2 */}
            <div className="tab-pane" id="tab-3">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-12 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Predictive Analytics</h3>
                  <p className="fst-italic">
                    We empower businesses to look beyond the present and peer
                    into the future with the precision of Predictive Analytics.
                    Harness the power of data to forecast trends, make informed
                    decisions, and stay steps ahead in your industry.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Forecasting Future Trends: </strong>
                      Predictive Analytics allows you to gaze into the future,
                      forecasting trends and market shifts based on historical
                      data and advanced modeling techniques.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>
                        Smart Decision-Making with Predictive Insights:{" "}
                      </strong>
                      Gain a competitive edge by making informed decisions.
                      Predictive Analytics provides actionable insights, guiding
                      strategic choices with a focus on future outcomes.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>
                        Risk Mitigation and Opportunity Identification:{" "}
                      </strong>
                      Navigate uncertainties with confidence. Identify potential
                      risks and seize opportunities before they unfold, giving
                      your business a proactive advantage.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* End Tab Content 3 */}
            <div className="tab-pane" id="tab-4">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-12 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Prescriptive Analytics</h3>
                  <p className="fst-italic">
                    We don't just predict the future, we shape it with the
                    precision of Prescriptive Analytics. Elevate your
                    decision-making to new heights by not only anticipating
                    outcomes but prescribing the best actions to optimize your
                    business strategies.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>
                        Beyond Prediction to Actionable Insights:{" "}
                      </strong>
                      Prescriptive Analytics doesn't stop at forecasting; it
                      prescribes the best course of action. Move beyond insights
                      to strategic, data-driven decisions that drive success.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>
                        Optimizing Strategies for Maximum Impact:{" "}
                      </strong>
                      Fine-tune your business strategies with precision.
                      Prescriptive Analytics identifies the most effective
                      actions to achieve your goals and maximize outcomes.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>
                        Dynamic Adaptation to Changing Conditions:
                      </strong>
                      Stay agile in a dynamic business environment. Our
                      Prescriptive Analytics solutions adapt in real-time to
                      changing conditions, ensuring your strategies remain
                      effective.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* End Tab Content 4 */}
            <div className="tab-pane" id="tab-5">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-8 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Testing</h3>
                  <p className="fst-italic">
                    The testing phase involves various types of testing, such as
                    unit testing, integration testing, system testing, and user
                    acceptance testing (UAT). The goal is to identify and fix
                    defects and ensure that the software meets the specified
                    requirements.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Integration Testing: </strong>
                      Verify that the different modules work together as
                      intended.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>System Testing: </strong>
                      Conduct comprehensive tests to ensure the entire system
                      functions as expected.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>User Acceptance Testing (UAT): </strong>
                      Validate the system with end-users to ensure it meets
                      their requirements.
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-4 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/testing.svg`}
                    alt=""
                    className="img-fluid"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            {/* End Tab Content 5 */}
            <div className="tab-pane" id="tab-6">
              <div className="row gy-4 d-flex align-items-center">
                <div
                  className="col-lg-8 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <h3>Maintenance</h3>
                  <p className="fst-italic">
                    After deployment, the software enters the maintenance phase,
                    during which developers address any issues that arise in the
                    production environment, apply updates, and provide ongoing
                    support. This phase may include regular updates, bug fixes,
                    and feature enhancements.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Bug Fixes: </strong>
                      Address and resolve any issues or bugs that arise.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Enhancements: </strong>
                      Implement additional features or improvements based on
                      user feedback.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" />
                      <strong>Monitoring: </strong>
                      Continuously monitor the system's performance and address
                      any emerging issues.
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-4 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/software-development/maintenance.svg`}
                    alt=""
                    className="img-fluid"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            {/* End Tab Content 6 */}
          </div>
        </div>
      </section>

      <section className="our-tech" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Technologies We Use</h2>
          </div>
          <div className="tech-carousel swiper">
            <div class="swiper-wrapper">
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/power-bi.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Power BI</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/tableau.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Tableau</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{
                      height: "100px",
                      width: "100px",
                      border: "none",
                      rowGap: "32px",
                    }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/pegasystems.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Pega Systems</p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/oracle-cloud.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Oracle Cloud</p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/microsoft-dynamics-365.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>PHP</p>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/sap.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>SAP</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/power-apps.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Power Apps</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/power-automate.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Power Automate</p>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="d-flex flex-column align-items-center">
                  <img
                    alt=""
                    style={{ height: "100px", width: "100px", border: "none" }}
                    src={`${process.env.PUBLIC_URL}/assets/icons/technologies/power-virtual-agents.svg`}
                  />
                  <p style={{ fontSize: "2.4rem" }}>Power Virtual Agents</p>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </section>

      <ContactUs />
    </>
  );
}
export default BusinessAnalytics;
