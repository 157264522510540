import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration={500}
    >
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="about-us">About us</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="blog">Blog</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="careers">Careers</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="services/software-development-and-maintenance">
                    Software Development and Maintainence
                  </Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="/services/business-intelligence-and-analytics">
                    Business Intelligence and Analytics
                  </Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="/services/software-integrations">Integrations</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="/services/cloud-services">Cloud Services</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Industries We Serve</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="industries/healthcare">Healthcare</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="industries/retail">Retail</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="industries/telecom">Telecom</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="industries/pharma">Pharma</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="industries/finance">Finance</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="industries/real-estate">Real Estate</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  <Link to="industries/tour-and-travel">Tour &amp; Travel</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                Binlytix Solutions Pvt. Ltd. <br />
                Sri Sri Towers, 201/202, Rd Number 1, KPHB Phase 1, Kukatpally,
                Hyderabad, Telangana-500072.
                <br />
                India <br />
                <br />
                <strong>Phone:</strong>
                <a
                  href="tel:+914035511051"
                  style={{ color: "rgb(225, 225, 225)" }}
                >
                  {" "}
                  (+91) 040 35511051
                </a>
                <br />
                <strong>Mobile:</strong>
                <a
                  href="tel:+916305473076"
                  style={{ color: "rgb(225, 225, 225)" }}
                >
                  {" "}
                  +91 6305473076
                </a>
                <br />
                <strong>For Queries:</strong>
                <a
                  href="tel:+914035510801"
                  style={{ color: "rgb(225, 225, 225)" }}
                >
                  {" "}
                  (+91) 040 35510801
                </a>
                <br />
                <strong>Email:</strong>
                <a
                  href="mailto:contact@binytix.com"
                  style={{ color: "rgb(225, 225, 225)" }}
                >
                  {" "}
                  contact@binytix.com
                </a>
                <br />
              </p>
            </div>
            <div className="col-lg-12 footer-info">
              <h3>Get connected with us on social media</h3>
              <div class="social-links mt-3">
                <a
                  href="https://www.linkedin.com/company/binlytix-solutions/"
                  class="linkedin"
                >
                  <i class="bx bxl-linkedin"></i>
                </a>
                <a href="https://twitter.com/binlytix284438" class="twitter">
                  <i class="bx bxl-twitter"></i>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61553498813696"
                  class="facebook"
                >
                  <i class="bx bxl-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/binlytixdigital/"
                  class="instagram"
                >
                  <i class="bx bxl-instagram"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCS52ExvvbtrR2-a-qpoMUTw"
                  class="youtube"
                >
                  <i class="bx bxl-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          © Copyright{" "}
          <strong>
            <span>Binlytix</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by{" "}
          <a href="https://binlytix.com/">Binlytix Solutions Pvt. Ltd.</a>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
