import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function Retail() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Industries</li>
              <li>Retail</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left, rgba(0,0, 0, 0.8),rgba(0, 0, 0, 0.8)),url(${process.env.PUBLIC_URL}/assets/img/industries/retail.jpg)`,
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Retail
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                At BINLYTIX, we are committed to revolutionize the retail
                industry through cutting-edge software solutions that enhance
                customer experiences, streamline operations, and drive growth.
                With a deep understanding of the dynamic retail landscape, we
                have developed a comprehensive suite of software products
                designed to empower retailers, optimize processes, and elevate
                brand engagement.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Point-of-Sale (POS) &amp; Checkout Solutions</a>
                </h4>
                <p className="description">
                  We specialize in delivering cutting-edge Point of Sale (POS)
                  and Checkout Solutions that empower businesses to streamline
                  their operations, enhance customer experiences &amp; boost
                  profitability.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>E-Commerce &amp; Online Retail Solutions</a>
                </h4>
                <p className="description">
                  Our comprehensive suite of software &amp; services empowers
                  businesses to establish a strong online presence, optimize
                  operations &amp; deliver exceptional shopping experiences to
                  their customers.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Retail Analytics &amp; Insights</a>
                </h4>
                <p className="description">
                  BINLYTIX is your trusted partner in transforming retail
                  operations through powerful analytics &amp; insights. With our
                  state-of-the-art software solutions, we empower retailers to
                  make data-driven decisions, optimize strategies.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>Customer Relationship Management (CRM)</a>
                </h4>
                <p className="description">
                  We develop CRM software that allows retailers to build strong
                  relationships with customers through personalized marketing,
                  loyalty programs, and effective communication
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                At the heart of our mission is the commitment to empower
                retailers with the tools they need to succeed in a rapidly
                evolving market. Here's how we contribute to the retail
                ecosystem.
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bi bi-check" />
                  Point-of-Sale (POS) &amp; Checkout Solutions
                </div>
                <p>
                  Our advanced POS software offers retailers a seamless and
                  intuitive platform to process transactions, manage inventory,
                  and provide efficient customer service. With customizable
                  features tailored to different retail environments, we enhance
                  the checkout experience for both customers and staff.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  E-Commerce &amp; Online Retail Solutions
                </div>
                <p>
                  In today's digital age, our e-commerce solutions enable
                  retailers to establish a strong online presence and seamlessly
                  manage online sales. We provide robust platforms that
                  integrate with existing systems, making online retail
                  operations efficient and customer-friendly.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Inventory &amp; Supply Chain Management
                </div>
                <p>
                  Our software streamlines inventory management by providing
                  real-time insights into stock levels, reordering needs, and
                  product performance. By optimizing supply chain processes, we
                  help retailers reduce costs, minimize stockouts, and enhance
                  overall operational efficiency.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Customer Relationship Management (CRM)
                </div>
                <p>
                  We develop CRM software that allows retailers to build strong
                  relationships with customers through personalized marketing,
                  loyalty programs, and effective communication. This leads to
                  increased customer retention and brand loyalty.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Retail Analytics &amp; Insights
                </div>
                <p>
                  Our data analytics solutions enable retailers to extract
                  actionable insights from their sales data, customer behavior,
                  and market trends. By leveraging these insights, retailers can
                  make informed decisions that drive sales growth and improve
                  overall business performance.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Mobile Apps &amp; Omni-Channel Integration
                </div>
                <p>
                  We create mobile applications that enhance the shopping
                  experience by offering features like mobile payments,
                  personalized recommendations, and in-app promotions.
                  Additionally, we facilitate seamless integration across
                  different retail channels, ensuring a consistent experience
                  for customers.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Loss Prevention &amp; Security Solutions
                </div>
                <p>
                  Recognizing the importance of security in retail, our software
                  includes features that enhance loss prevention through
                  surveillance, inventory tracking, and fraud detection, helping
                  retailers safeguard their assets and profits.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Employee Management &amp; Training
                </div>
                <p>
                  Our solutions extend to employee management, offering tools
                  for scheduling, performance tracking, and training. By
                  optimizing workforce management, retailers can improve
                  employee satisfaction and customer service.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default Retail;
