import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";

function Demo() {
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onSubmit = async (data, e) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      setLoading(true);
      const fetchResponse = await fetch(window["DEMO_URL"], settings);
      const data = await fetchResponse.json();
      toast.success(data.message);
      reset();
      handleClose();
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section id="demo">
        <div className="container">
          <button
            className="btn btn-primary"
            // data-bs-toggle="modal"
            // data-bs-target="#demomodal"

            onClick={handleShow}
          >
            Demo
          </button>
          <Modal
            centered
            className="contact fade"
            show={show}
            onHide={handleClose}
          >
            <div>
              <div className="modal-content">
                <form
                  className="php-email-form form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: "32px",
                      fontWeight: "500",
                    }}
                  >
                    Schedule A Demo
                  </h3>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "grey",
                    }}
                  >
                    Get a demo with one of our technical representatives. We'll
                    do our best to reach to you within 24 hours
                  </p>
                  <div className="form-group mt-3 mb-3">
                    <input
                      {...register("name")}
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        {...register("email")}
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        {...register("phone")}
                        type="text"
                        className="form-control"
                        placeholder="Mobile"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group mt-3 mb-3">
                    <input
                      type="text"
                      {...register("subject")}
                      className="form-control"
                      placeholder="What would you like to see..."
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      {...register("message")}
                      rows="7"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading"></div>
                    <div className="error-message"></div>
                    <div className="sent-message"></div>
                  </div>
                  <div className="text-center mt-3">
                    {!loading && (
                      <button type="submit" disabled={loading}>
                        <span
                          style={{
                            display: "inline-block",
                            fontWeight: "600",
                            fontSize: "16px",
                          }}
                        >
                          Book a Demo
                        </span>
                      </button>
                    )}
                    <div
                      class={loading && `loader`}
                      style={{ display: "inline-block", marginTop: "24px" }}
                    ></div>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        </div>
      </section>
    </>
  );
}
export default Demo;
