import { Link } from "react-router-dom";

function Services() {
  return (
    <section className="services">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>
        <div className="row">
          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch flip-card"
            data-aos="fade-up"
          >
            <div className="flip-card-inner">
              <div className="flip-card-front icon-box icon-box-pink">
                <div className="icon">
                  <img alt="" src="assets/icons/services/analytics.svg" />
                </div>
                <h4 className="title">Business Analytics</h4>
                <p className="description">
                  Analyze, visualize, and make informed decisions with business
                  intelligence and analytics. Instant visibility on business
                  performance and for better business outcomes.
                </p>
                <div className="turn-around-arrow">
                  <i className="bi bi-arrow-right" />
                </div>
              </div>
              <div className="flip-card-back flip-card-back-analytics">
                <p className="description">
                  Discover actionable insights from your data with our robust
                  business intelligence solutions. Our tools gather, analyze,
                  &amp; visualize data to provide you with a clear understanding
                  of your business landscape.
                </p>
                <Link
                  to="services/business-intelligence-and-analytics"
                  className="read-more-btn"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch flip-card"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="icon-box icon-box-cyan">
                  <div className="icon">
                    <img
                      alt=""
                      src="assets/icons/services/software-development.svg"
                    />
                  </div>
                  <h4 className="title">
                    Software Development &amp; Maintenance
                  </h4>
                  <p className="description">
                    We offer customized Software Solutions to help our clients
                    achieve their goals and deliver high-quality solutions that
                    are tailored to meet specific requirements.
                  </p>
                  <div className="turn-around-arrow">
                    <i className="bi bi-arrow-right" />
                  </div>
                </div>
              </div>
              <div className="flip-card-back flip-card-back-software">
                <p className="description">
                  We create software applications using coding, testing &amp;
                  design to meet specific needs, often involving planning,
                  analysis, and deployment. We also offer support after software
                  deployment, including bug fixes, updates &amp; security
                  enhancements.
                </p>
                <Link
                  to="services/software-development-and-maintenance "
                  className="read-more-btn"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch flip-card"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="icon-box icon-box-green">
                  <div className="icon">
                    <img
                      alt=""
                      src="assets/icons/services/data-integration.svg"
                    />
                  </div>
                  <h4 className="title">Integration</h4>
                  <p className="description">
                    Establish efficient business process and break down silos
                    within your workflows. Enable process management, reduce IT
                    complexity, and increase IT agility.
                  </p>
                  <div className="turn-around-arrow">
                    <i className="bi bi-arrow-right" />
                  </div>
                </div>
              </div>
              <div className="flip-card-back flip-card-back-integrations">
                <p className="description">
                  Our software solutions are designed to seamlessly integrate
                  with your existing tools &amp; systems, streamlining your
                  operations &amp; boosting productivity. Say goodbye to manual
                  data transfer &amp; repetitive tasks our integrations.
                </p>
                <Link
                  to="services/software-integrations"
                  className="read-more-btn"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-3 d-flex align-items-stretch flip-card"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="icon-box icon-box-blue">
                  <div className="icon">
                    <img alt="" src="assets/icons/services/cloud.svg" />
                  </div>
                  <h4 className="title">Cloud Services</h4>
                  <p className="description">
                    Cloud solutions deliver 1.7 times the ROI of on-premise
                    solutions. Achieve economies of scale, and reduce
                    infrastructure spending with cloud computing.
                  </p>
                  <div className="turn-around-arrow">
                    <i className="bi bi-arrow-right" />
                  </div>
                </div>
              </div>
              <div className="flip-card-back flip-card-back-cloud">
                <p className="description">
                  Say goodbye to traditional hardware constraints &amp; hello to
                  scalability, flexibility &amp; cost-efficiency. Our cloud
                  solutions empower your business with secure data storage,
                  seamless collaboration access from anywhere, anytime.
                </p>
                <Link to="services/cloud-services" className="read-more-btn">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Services;
