import { Link } from "react-router-dom";

function Industries() {
  return (
    <section id="home-industries">
      <div className="container overflow-hidden">
        <div className="section-title">
          <h2>Industries We Serve</h2>
        </div>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-sm-12 p-2 member-wrap"
            data-aos="fade-up"
          >
            <Link to="industries/healthcare">
              <div className="member d-flex flex-column align-items-center p-4">
                <div className="industry-img member-img">
                  <img
                    src="assets/img/home-industries/healthcare.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h3>Healthcare</h3>
                  <p>
                    We provide solutions such as electronic health records
                    (EHRs) &amp; analytics tools. Our role is to improve patient
                    care, enhance operational efficiency &amp; advance medical
                    practices through innovative software applications.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 p-2 member-wrap"
            data-aos="fade-up"
          >
            <Link to="industries/retail">
              <div className="member d-flex flex-column align-items-center p-4">
                <div className="industry-img member-img">
                  <img
                    src="assets/img/home-industries/retail.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h3>Retail</h3>
                  <p>
                    Our solutions include point-of-sale systems, e-commerce
                    platforms, customer relationship management tools &amp;
                    analytics software. Their role is to optimize retail
                    operations, drive business growth through innovative
                    software applications.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 p-2 member-wrap"
            data-aos="fade-up"
          >
            <Link to="industries/telecom">
              <div className="member d-flex flex-column align-items-center p-4">
                <div className="industry-img member-img">
                  <img
                    src="assets/img/home-industries/telecom.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h3>Telecom</h3>
                  <p>
                    We specialize in developing solutions including billing
                    systems, CRM software, network management tools, and
                    communication APIs which improve customer experiences &amp;
                    driving innovation in the industry.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 p-2 member-wrap"
            data-aos="fade-up"
          >
            <Link to="industries/pharma">
              <div className="member d-flex flex-column align-items-center p-4">
                <div className="industry-img member-img">
                  <img
                    src="assets/img/home-industries/pharma.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h3>Pharma</h3>
                  <p>
                    We develop efficient software for clinical trials
                    management, supply chain optimization &amp;
                    pharmacovigilance. Their role is to streamline
                    pharmaceutical operations, accelerate drug development,
                    ensure compliance with industry regulations
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 p-2 member-wrap"
            data-aos="fade-up"
          >
            <Link to="industries/finance">
              <div className="member d-flex flex-column align-items-center p-4">
                <div className="industry-img member-img">
                  <img
                    src="assets/img/home-industries/finance.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h3>Finance</h3>
                  <p>
                    We offer software solutions for accounting, payment
                    processing &amp; investment analysis. Their role is to
                    provide robust and secure software applications that
                    streamline financial processes, improve decision-making.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 p-2 member-wrap"
            data-aos="fade-up"
          >
            <Link to="industries/real-estate">
              <div className="member d-flex flex-column align-items-center p-4">
                <div className="industry-img member-img">
                  <img
                    src="assets/img/home-industries/real-estate.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h3>Real Estate</h3>
                  <p>
                    We provide solutions such as software for customer
                    relationship management (CRM), virtual property tours, and
                    analytics tools. Their role is to optimize enhance customer
                    experiences &amp; facilitate efficient property transactions
                    through software applications.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 p-2 member-wrap"
            data-aos="fade-up"
          >
            <Link to="industries/tour-and-travel">
              <div className="member d-flex flex-column align-items-center p-4">
                <div className="industry-img member-img">
                  <img
                    src="assets/img/home-industries/travel.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h3>Tour &amp; Travel</h3>
                  <p>
                    Our solutions include software for travel booking, online
                    reservation systems, travel agency management, and travel
                    analytics. Their role is to streamline travel operations,
                    enhance customer experiences, and facilitate seamless travel
                    arrangements.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 p-2 member-wrap"
            data-aos="fade-up"
          >
            <Link to="industries/technology">
              <div className="member d-flex flex-column align-items-center p-4">
                <div className="industry-img member-img">
                  <img
                    src="assets/img/home-industries/tech.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h3>Technology</h3>
                  <p>
                    We create products for data analytics, artificial
                    intelligence, cloud computing, enterprise software, and
                    mobile applications. Their role is to drive improve business
                    processes &amp; address the evolving needs of the digital
                    world.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Industries;
