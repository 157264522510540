import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function SoftwareIntegrations() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Services</li>
              <li>Software Integrations</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero-services d-flex justify-cntent-center align-items-center"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(
            to left,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7)
          ),
          url(${process.env.PUBLIC_URL}/assets/img/software-integrations/software_integrations.jpg)`,
          backgroundPosition: "0 20%",
        }}
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: "600",
                }}
                className="animate__animated animate__fadeInDown"
              >
                Software Integrations
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Establish efficient business process and break down silos within
                your workflows. Enable process management, reduce IT complexity,
                and increase IT agility.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="services-integrations-solutions">
        <div className="container">
          <div className="row">
            <h3 className="col-lg-12">Integration Solutions</h3>
            <ul className="col-lg-12">
              <li>
                <i className="bi bi-check2-circle" /> Enterprise Application
                Integration
              </li>
              <li>
                <i className="bi bi-check2-circle" />
                Third Party System Integration
              </li>
              <li>
                <i className="bi bi-check2-circle" />
                Cloud Integration
              </li>
              <li>
                <i className="bi bi-check2-circle" />
                Database Integrations
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section id="services-integrations-approach">
        <div className="container">
          <div className="section-title">
            <h2>Our Approach</h2>
          </div>
          <div className="row" data-aos="fade-up">
            <p className="col-lg-12">
              We believe in the power of seamless software integrations. Our
              mission is to empower businesses to optimize their operations,
              harness data effectively, and unlock unparalleled productivity.
              Discover how our software integration solutions can revolutionize
              the way you work and help you stay ahead in today's competitive
              landscape.
            </p>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Analysis</h3>
                <p>
                  We conduct a thorough analysis of your existing software
                  infrastructure and business processes to identify integration
                  opportunities.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Custom Integration Plan</h3>
                <p>
                  Based on your requirements, we design a custom integration
                  plan, outlining the necessary steps to ensure a smooth
                  integration process.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Development &amp; Testing</h3>
                <p>
                  Our skilled developers implement the integration plan,
                  rigorously testing the integration to ensure it functions
                  flawlessly.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-3 member"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Deployment &amp; Support</h3>
                <p>
                  Once the integration is complete, we seamlessly deploy it into
                  your system and provide ongoing support and maintenance to
                  guarantee optimum performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Specialization</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                We facilitate the seamless interaction and interoperability of
                different software systems. Software integrations involve
                combining multiple software applications, services, or
                components to work together as a unified solution. This can be
                done to enhance functionality, improve efficiency, streamline
                processes, and provide a better user experience.
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bx bx-check" />
                  Tailored Solutions
                </div>
                <p>
                  We understand that every business is unique, and off-the-shelf
                  solutions may not always meet your needs. Our approach
                  involves working closely with you to understand your
                  processes, goals, and challenges, and then crafting
                  integration solutions that are tailored to your specific
                  needs.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Seamless Collaboration
                </div>
                <p>
                  We believe that effective integration is all about enabling
                  different software systems to work in harmony. Whether it's
                  connecting CRM and ERP systems, e-commerce platforms, or IoT
                  devices, we ensure that your software components communicate
                  seamlessly, resulting in a unified and synchronized ecosystem.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  API Expertise
                </div>
                <p>
                  APIs are the building blocks of modern software integrations.
                  Our team excels in developing robust APIs that facilitate data
                  exchange, streamline processes, and enable your systems to
                  communicate securely and efficiently.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Data Integrity and Security
                </div>
                <p>
                  We prioritize data integrity and security throughout the
                  integration process. From data transformation to encryption
                  and compliance with industry standards, we ensure that your
                  sensitive information remains protected at all times.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Scalability
                </div>
                <p>
                  As your business evolves, so should your software ecosystem.
                  Our integration solutions are designed to be scalable,
                  accommodating growth and future integrations seamlessly,
                  without disrupting your operations.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <ContactUs />
    </>
  );
}
export default SoftwareIntegrations;
