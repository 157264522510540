import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";
function Clinatix() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Products</li>
              <li>Clinatix</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left,rgba(0,0, 0, 0.8),rgba(0, 0, 0, 0.8)),url(${process.env.PUBLIC_URL}/assets/img/products/clinatix.jpg)`,
          backgroundPosition: "10% 50%",
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h2
                className="animate__animated animate__fadeInDown"
                style={{ color: "white" }}
              >
                Clinatix
              </h2>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Unlock the power of data-driven decision-making with our
                comprehensive Analytics and Business Intelligence (BI)
                solutions. At BINLYTIX, we specialize in delivering advanced
                analytics, insightful reports, and actionable insights that
                drive business growth and innovation.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Data Integration and Transformation</a>
                </h4>
                <p className="description">
                  Consolidate data from multiple sources, including databases,
                  cloud applications, and IoT devices, into a centralized data
                  warehouse. Our robust data integration capabilities ensure
                  that you have a unified view of your organization's data for
                  analysis.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>Data Visualization</a>
                </h4>
                <p className="description">
                  Transform raw data into visually appealing and interactive
                  dashboards and reports. Our intuitive data visualization tools
                  allow you to explore trends, identify patterns, and gain
                  valuable insights at a glance.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Advanced Analytics</a>
                </h4>
                <p className="description">
                  Leverage advanced analytics techniques such as predictive
                  modeling, machine learning, and AI algorithms to uncover
                  hidden opportunities and mitigate risks. Our analytics
                  solutions help you forecast future trends, optimize
                  operations, and make proactive decisions.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>Self-Service BI</a>
                </h4>
                <p className="description">
                  Empower business users to explore data and create their own
                  reports and dashboards with our self-service BI tools. Our
                  user-friendly interfaces require minimal IT intervention,
                  enabling faster insights and greater agility in
                  decision-making.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                At BINLYTIX, we're committed to helping organizations harness
                the full potential of their data through advanced analytics and
                business intelligence. Partner with us to unlock actionable
                insights, drive strategic decision-making, and achieve your
                business objectives with confidence.
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bx bx-check" />
                  Real-Time Reporting
                </div>
                <p>
                  Access up-to-date information and insights in real-time with
                  our real-time reporting capabilities. Monitor key metrics,
                  track KPIs, and respond to changes in your business
                  environment promptly to stay ahead of the competition.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Mobile BI
                </div>
                <p>
                  Stay connected and informed on the go with our mobile BI
                  solutions. Access dashboards and reports from any device,
                  anytime, anywhere, enabling data-driven decision-making even
                  when you're away from the office.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Data Governance and Security
                </div>
                <p>
                  Ensure data integrity, compliance, and security with our
                  robust data governance and security features. Our solutions
                  adhere to industry standards and best practices to protect
                  sensitive information and maintain regulatory compliance.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Improved Decision-Making
                </div>
                <p>
                  Make informed decisions based on data-driven insights rather
                  than intuition or guesswork. Our analytics and BI solutions
                  provide actionable intelligence that empowers you to optimize
                  processes, mitigate risks, and seize opportunities with
                  confidence.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Increased Operational Efficiency
                </div>
                <p>
                  Streamline operations, eliminate bottlenecks, and improve
                  resource allocation with insights derived from our analytics
                  solutions. Identify inefficiencies, automate repetitive tasks,
                  and optimize workflows to drive operational excellence.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Enhanced Customer Experience
                </div>
                <p>
                  Gain a deeper understanding of customer behavior, preferences,
                  and needs through advanced analytics. Personalize marketing
                  campaigns, tailor product offerings, and deliver exceptional
                  customer experiences that foster loyalty and satisfaction.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Competitive Advantage
                </div>
                <p>
                  Stay ahead of the competition by leveraging data as a
                  strategic asset. Our analytics and BI solutions help you
                  uncover market trends, identify emerging opportunities, and
                  differentiate your business in a crowded marketplace.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  ROI and Business Growth
                </div>
                <p>
                  Maximize ROI on your data investments and fuel business growth
                  with insights derived from our analytics solutions. Identify
                  revenue-generating opportunities, optimize pricing strategies,
                  and drive innovation to achieve sustainable business success.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default Clinatix;
