import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function Clivois() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Products</li>
              <li>Clivois</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left,rgba(0,0, 0, 0.7),rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/assets/img/products/clivois.jpg)`,
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h2
                className="animate__animated animate__fadeInDown"
                style={{ color: "white" }}
              >
                Clivois
              </h2>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                At BINLYTIX, we are dedicated to providing comprehensive
                solutions for call centers to optimize their operations, enhance
                customer service, and drive business growth. Our Inbound and
                Outbound Call Center Software is designed to empower your team
                with the tools they need to deliver exceptional customer
                experiences and achieve outstanding results.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Inbound Call Management</a>
                </h4>
                <p className="description">
                  Our software offers robust features for managing inbound calls
                  efficiently. From intelligent call routing to IVR (Interactive
                  Voice Response) systems, we ensure that incoming calls are
                  routed to the most appropriate agent, reducing wait times and
                  enhancing customer satisfaction.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>Outbound Dialing</a>
                </h4>
                <p className="description">
                  With our outbound dialing capabilities, your team can
                  efficiently reach out to customers, prospects, and leads.
                  Whether it's for sales, marketing campaigns, or customer
                  follow-ups, our software enables automated dialing, predictive
                  dialing, and preview dialing to maximize agent productivity
                  and campaign effectiveness.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Call Recording and Monitoring</a>
                </h4>
                <p className="description">
                  Gain valuable insights into customer interactions and agent
                  performance with our call recording and monitoring features.
                  Supervisors can listen in on calls, provide real-time
                  coaching, and access recorded calls for quality assurance and
                  training purposes.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>CRM Integration</a>
                </h4>
                <p className="description">
                  Seamlessly integrate our call center software with your CRM
                  (Customer Relationship Management) system to access customer
                  information, history, and preferences during calls. This
                  empowers agents to deliver personalized service and ensures a
                  seamless omnichannel experience for customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                Transform your call center operations and elevate your customer
                service with our Inbound and Outbound Call Center Software.
                Contact us to schedule a demo and see how our solutions can help
                you achieve your business objectives.
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bx bx-check" />
                  Reporting and Analytics
                </div>
                <p>
                  Our software provides comprehensive reporting and analytics
                  dashboards to track key performance metrics, monitor campaign
                  effectiveness, and identify areas for improvement. With
                  real-time insights, you can make data-driven decisions to
                  optimize your call center operations.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Multi-channel Support
                </div>
                <p>
                  In addition to voice calls, our software supports
                  multi-channel communication including email, chat, and SMS.
                  This enables your team to engage with customers across their
                  preferred channels, increasing accessibility and customer
                  satisfaction.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Improved Efficiency
                </div>
                <p>
                  Streamline your call center operations and increase agent
                  productivity with our intuitive software tools and automation
                  features.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Enhanced Customer Experience
                </div>
                <p>
                  Deliver personalized, responsive service to your customers
                  with intelligent call routing, CRM integration, and
                  multi-channel support.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Increased Sales and Conversions
                </div>
                <p>
                  Drive sales and revenue growth with targeted outbound
                  campaigns, efficient lead management, and data-driven insights
                  into customer behavior.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Better Agent Performance
                </div>
                <p>
                  Empower your agents with tools for call recording, monitoring,
                  and real-time coaching to improve their skills and
                  performance.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  Scalability and Flexibility
                </div>
                <p>
                  Our software is scalable to accommodate the changing needs of
                  your business, whether you're a small startup or a large
                  enterprise. Customize and configure the software to suit your
                  unique requirements.
                </p>
              </li>
              <li>
                <div>
                  <i className="bx bx-check" />
                  CRM Integration
                </div>
                <p>
                  Seamlessly integrate our call center software with your CRM
                  (Customer Relationship Management) system to access customer
                  information, history, and preferences during calls. This
                  empowers agents to deliver personalized service and ensures a
                  seamless omnichannel experience for customers.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default Clivois;
