import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function Pharma() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Industries</li>
              <li>Pharma</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left, rgba(0,0, 0, 0.7),rgba(0, 0, 0, 0.7)),url(${process.env.PUBLIC_URL}/assets/img/industries/pharma.jpg)`,
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Pharma
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Welcome to BINLYTIX, where we are at the forefront of
                revolutionizing the pharmaceutical industry through cutting-edge
                software solutions. Our dedication to enhancing patient care,
                streamlining operations, and advancing research and development
                makes us a key player in shaping the future of pharmaceuticals.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Clinical Trial Management</a>
                </h4>
                <p className="description">
                  We specialize in providing cutting-edge clinical trial
                  management solutions that empower researchers, pharmaceutical
                  companies &amp; healthcare institutions to bring life-changing
                  treatments to the world.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>Regulatory Compliance &amp; Quality Assurance</a>
                </h4>
                <p className="description">
                  BINLYTIX is your trusted partner in ensuring regulatory
                  compliance &amp; quality assurance excellence that empower
                  businesses to navigate complex regulatory landscapes &amp;
                  maintain the highest quality standards.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Medical Information Systems</a>
                </h4>
                <p className="description">
                  We specialize in providing state-of-the-art medical
                  information systems that empower healthcare providers to
                  enhance patient care, streamline operations, and drive medical
                  excellence.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>Supply Chain Optimization</a>
                </h4>
                <p className="description">
                  BINLYTIX, where supply chain excellence meets technology. We
                  provide cutting-edge supply chain optimization solutions that
                  empower businesses to streamline operations, reduce costs
                  &amp; enhance efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                At BINLYTIX, we recognize the critical role software plays in
                driving advancements in the pharmaceutical sector. Here's how we
                contribute to the industry's growth and innovation:
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bi bi-check" />
                  Clinical Trial Management
                </div>
                <p>
                  We develop software solutions that facilitate efficient
                  management of clinical trials, from patient recruitment and
                  data collection to monitoring and analysis. Our tools enhance
                  trial accuracy, accelerate timelines, and lead to faster drug
                  development.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Regulatory Compliance &amp; Quality Assurance:
                </div>
                <p>
                  Our software ensures pharmaceutical companies adhere to
                  stringent regulatory standards. We provide solutions that
                  simplify compliance processes, quality control, and reporting,
                  fostering adherence to industry regulations.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Pharmacovigilance &amp; Drug Safety
                </div>
                <p>
                  We create systems that enable proactive monitoring of adverse
                  events and drug safety data. Our software helps pharmaceutical
                  companies swiftly identify potential risks and take necessary
                  actions, ensuring patient safety.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Supply Chain Optimization
                </div>
                <p>
                  Our software solutions optimize pharmaceutical supply chains,
                  from manufacturing and distribution to inventory management.
                  This minimizes waste, reduces costs, and ensures seamless
                  availability of vital medications.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Drug Development and Research
                </div>
                <p>
                  We support drug discovery and development by providing data
                  analytics tools that help researchers analyze complex
                  biological data, accelerating the identification of potential
                  drug candidates.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Medical Information Systems
                </div>
                <p>
                  We develop platforms that facilitate secure storage and
                  retrieval of medical information, enhancing communication
                  &amp; collaboration among healthcare professionals and
                  researchers.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Patient Engagement &amp; Education
                </div>
                <p>
                  Our patient-centric applications empower individuals to manage
                  their health by providing access to medical information,
                  medication reminders, and educational resources, enhancing
                  overall patient outcomes.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default Pharma;
