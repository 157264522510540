import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function Finance() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Industries</li>
              <li>Finance</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage: `linear-gradient(to left,rgba(0,0, 0, 0.7),rgba(0, 0, 0, 0.7)),url(${process.env.PUBLIC_URL}/assets/img/industries/finance.jpg)`,
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Finance
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Welcome to BINLYTIX, a leader in revolutionizing the finance
                industry through cutting-edge software solutions. Our unwavering
                commitment to enhancing financial operations, optimizing
                processes, and driving informed decisions positions us at the
                forefront of shaping the future of finance.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services industries-services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink">
                <h4 className="title">
                  <a>Financial Management Solutions</a>
                </h4>
                <p className="description">
                  BINLYTIX is your partner in elevating financial management to
                  new heights. We deal with financial management solutions that
                  empower businesses to optimize financial operations &amp;
                  enhance decision-making.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box icon-box-cyan">
                <h4 className="title">
                  <a>Risk Management and Compliance</a>
                </h4>
                <p className="description">
                  We specialize in provide customizable approach through
                  advanced software solutions that empower businesses to
                  proactively manage risks, maintain compliance &amp; achieve
                  operational excellence.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-green">
                <h4 className="title">
                  <a>Investment &amp; Wealth Management</a>
                </h4>
                <p className="description">
                  We offer advanced investment &amp; wealth management solutions
                  that empower individuals &amp; businesses to make informed
                  financial decisions, optimize portfolios &amp; secure their
                  financial future.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box icon-box-blue">
                <h4 className="title">
                  <a>Financial Analytics and Insights</a>
                </h4>
                <p className="description">
                  We develop data analytics tools that extract actionable
                  insights from financial data, enabling businesses to identify
                  trends, predict market movements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services-our-specialization"
        className="services-our-specialization-integrations industries-role"
      >
        <div className="container">
          <div className="section-title">
            <h2>Our Role</h2>
          </div>
          <div
            className="row services-our-specialization-list"
            data-aos="fade-up"
          >
            <div className="col-lg-12">
              <p>
                At BINLYTIX, we understand the pivotal role software plays in
                driving financial innovation. Here's how we contribute to the
                growth and transformation of the finance sector:
              </p>
            </div>
            <ul className="col-lg-12 services-our-specialization-list-2">
              <li>
                <div>
                  <i className="bi bi-check" />
                  Financial Management Solutions
                </div>
                <p>
                  We develop software that empowers businesses to manage their
                  finances efficiently. From budgeting and forecasting to
                  financial reporting and analysis, our solutions provide a
                  comprehensive view of financial health.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Digital Banking and Payment Systems
                </div>
                <p>
                  Our digital banking solutions facilitate seamless online
                  banking experiences and secure payment processing. We create
                  user-friendly interfaces that enhance customer engagement and
                  build trust in digital financial transactions.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Risk Management and Compliance
                </div>
                <p>
                  We provide software solutions that help financial institutions
                  identify, assess, and mitigate risks. Our compliance tools
                  ensure adherence to regulatory standards, safeguarding both
                  institutions and their clients.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Investment and Wealth Management
                </div>
                <p>
                  We support investment firms and advisors by creating software
                  that facilitates portfolio management, asset allocation, and
                  client engagement, leading to more informed investment
                  decisions.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Trading and Algorithmic Solutions
                </div>
                <p>
                  Our trading platforms and algorithmic software empower traders
                  with real-time market data, advanced analytics, and automated
                  trading strategies for enhanced decision-making.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Financial Analytics and Insights
                </div>
                <p>
                  We develop data analytics tools that extract actionable
                  insights from financial data, enabling businesses to identify
                  trends, predict market movements, and make informed strategic
                  choices.
                </p>
              </li>
              <li>
                <div>
                  <i className="bi bi-check" />
                  Blockchain &amp; Cryptocurrency Solutions
                </div>
                <p>
                  We leverage blockchain technology to create secure,
                  transparent, and efficient systems for digital transactions,
                  asset management, and decentralized finance (DeFi).
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default Finance;
