import Navbar from "./Navbar";
import Footer from "./Footer";
import Demo from "./Demo";
import SocialFloat from "./SocialFloat";
import BackToTop from "./BackToTop";
import { Outlet, useLocation } from "react-router";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";

function Index() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          style: {
            fontSize: "14px",
            fontWeight: "600",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Navbar />
      <Outlet />
      <Footer />
      <Demo />
      <SocialFloat />
      <BackToTop />
    </>
  );
}
export default Index;
