import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function Medicorm() {
  return (
    <>
      <div>
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Products</li>
                <li>Medicorm</li>
              </ol>
            </div>
          </div>
        </section>
        <section
          id="hero"
          style={{
            marginTop: "0",
            height: "80vh",
            backgroundImage: `linear-gradient(to left,rgba(0,0, 0, 0.8),rgba(0, 0, 0, 0.8)),
            url(${process.env.PUBLIC_URL}/assets/img/products/hms.jpg)`,
          }}
          className="industries-hero d-flex justify-cntent-center align-items-center"
        >
          <div
            id="heroCarousel"
            className="container carousel carousel-fade"
            data-bs-ride="carousel"
            data-bs-interval={5000}
          >
            <div className="carousel-item active">
              <div className="carousel-container">
                <h2
                  className="animate__animated animate__fadeInDown"
                  style={{ color: "white" }}
                >
                  Medicorm
                </h2>
                <p
                  className="animate__animated animate__fadeInUp"
                  style={{ color: "white" }}
                >
                  At BINLYTIX, we understand the complexities and challenges
                  faced by healthcare institutions in managing operations
                  efficiently while providing high-quality patient care. That's
                  why we've developed a comprehensive Hospital Management System
                  (HMS) tailored to meet the unique needs of healthcare
                  providers.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="services industries-services">
          <div className="container">
            <div className="section-title">
              <h2>Our Services</h2>
            </div>
            <div className="row">
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
              >
                <div className="icon-box icon-box-pink">
                  <h4 className="title">
                    <a>Patient Management</a>
                  </h4>
                  <p className="description">
                    Streamline patient registration, appointment scheduling, and
                    tracking of patient demographics and medical history. Our
                    system ensures smooth patient flow throughout their entire
                    journey within the hospital.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="icon-box icon-box-cyan">
                  <h4 className="title">
                    <a>Appointment Scheduling</a>
                  </h4>
                  <p className="description">
                    Simplify the process of booking appointments for patients,
                    doctors, and other healthcare professionals. Our intuitive
                    interface allows for easy appointment management, reducing
                    waiting times and optimizing resource utilization.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="icon-box icon-box-green">
                  <h4 className="title">
                    <a>Electronic Health Records (EHR)</a>
                  </h4>
                  <p className="description">
                    Digitize patient records securely with our EHR module.
                    Access comprehensive patient information, including medical
                    history, lab results, prescriptions, and treatment plans,
                    all in one centralized location.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-3 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="icon-box icon-box-blue">
                  <h4 className="title">
                    <a>Billing and Insurance Management</a>
                  </h4>
                  <p className="description">
                    Efficiently manage billing and insurance claims, reducing
                    administrative overhead and ensuring accurate reimbursement.
                    Our system automates billing processes, verifies insurance
                    coverage, and generates detailed invoices for patients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="services-our-specialization"
          className="services-our-specialization-integrations industries-role"
        >
          <div className="container">
            <div className="section-title">
              <h2>Our Role</h2>
            </div>
            <div
              className="row services-our-specialization-list"
              data-aos="fade-up"
            >
              <div className="col-lg-12">
                <p>
                  At BINLYTIX, we're committed to empowering healthcare
                  providers with cutting-edge technology to streamline
                  operations, enhance patient care, and drive better outcomes.
                  Partner with us to transform your hospital management
                  processes and deliver exceptional healthcare services to your
                  community.
                </p>
              </div>
              <ul className="col-lg-12 services-our-specialization-list-2">
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Inventory and Pharmacy Management
                  </div>
                  <p>
                    Keep track of medication stock levels, manage inventory, and
                    streamline pharmacy operations. Our system helps prevent
                    stockouts, reduces medication errors, and ensures timely
                    availability of essential drugs.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Laboratory Information System (LIS)
                  </div>
                  <p>
                    Improve the efficiency of laboratory workflows with our LIS
                    module. Track samples, manage test requests, and generate
                    accurate lab reports seamlessly, enhancing diagnostic
                    capabilities and patient care.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Staff and Resource Management
                  </div>
                  <p>
                    Optimize staff scheduling, allocate resources efficiently,
                    and track staff performance with our integrated management
                    tools. Ensure adequate staffing levels and maintain a
                    productive workforce to deliver exceptional care.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Analytics and Reporting
                  </div>
                  <p>
                    Gain valuable insights into hospital performance, patient
                    outcomes, and resource utilization through our advanced
                    analytics and reporting features. Make data-driven decisions
                    to enhance operational efficiency and clinical outcomes.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Improved Patient Care
                  </div>
                  <p>
                    By streamlining administrative tasks and providing easy
                    access to patient information, our HMS enables healthcare
                    providers to focus more on delivering personalized care and
                    improving patient outcomes.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Enhanced Efficiency
                  </div>
                  <p>
                    Automating processes and integrating various modules into a
                    single platform reduces manual errors, eliminates duplicate
                    tasks, and improves overall operational efficiency within
                    the hospital.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Cost Savings
                  </div>
                  <p>
                    Our HMS helps hospitals reduce costs associated with
                    administrative overhead, inventory management, and billing
                    errors, leading to significant cost savings in the long run.
                  </p>
                </li>
                <li>
                  <div>
                    <i className="bx bx-check" />
                    Compliance and Security
                  </div>
                  <p>
                    We prioritize data security and compliance with healthcare
                    regulations. Our system adheres to industry standards and
                    employs robust security measures to safeguard patient
                    information and ensure confidentiality.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <ContactUs />
    </>
  );
}
export default Medicorm;
