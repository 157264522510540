import { Link } from "react-router-dom";
import ContactUs from "../../Layouts/ContactUs";

function index() {
  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Careers</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Careers</li>
            </ol>
          </div>
        </div>
      </section>
      <section
        id="hero"
        style={{
          marginTop: "0",
          height: "80vh",
          backgroundImage:
            "linear-gradient(to left,rgba(0, 0,0, 0.7),rgba(0, 0, 0, 0.7)), url(assets/img/careers.jpg)",
          backgroundPosition: "bottom",
        }}
        className="industries-hero d-flex justify-cntent-center align-items-center"
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h1
                style={{
                  color: "white",
                  marginBottom: "3rem",
                  fontSize: "4rem",
                  fontWeight: 600,
                }}
                className="animate__animated animate__fadeInDown"
              >
                Welcome to Careers Portal
              </h1>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ color: "white" }}
              >
                Explore a diverse range of exciting career opportunities in
                cutting-edge technologies. Join our innovative team of
                professionals, working on challenging projects for global
                clients. Unlock your potential, grow your skills, and be part of
                a dynamic environment that fosters growth and success. Start
                your journey with us today!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services careers-open">
        <div className="container">
          <div className="section-title">
            <h2>Current Openings</h2>
          </div>
          <div className="row" id="careers_list">
            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch flip-card"
              data-aos="fade-up"
            >
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="icon-box icon-box-pink">
                    <div class="icon">
                      <img
                        alt=""
                        src="assets/icons/technologies/power-bi.svg"
                      />
                    </div>
                    <h4 class="title">
                      <Link>Power BI Developer</Link>
                    </h4>
                    <p class="description">
                      Position : Senior Power BI Developer <br />
                      Experience : 3 - 5 years
                      <br />
                      Location : Hyderabad <br />
                      Qualification : BE / B.Tech/ Any other degree
                    </p>
                    <div class="turn-around-arrow">
                      <i class="bi bi-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div class="flip-card-back">
                  <p class="description">
                    Are you a data visualization whiz with a passion for
                    transforming raw data into actionable insights? We want you
                    to be our Power BI Developer!
                  </p>
                  <button
                    class="activateButton read-more-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#applicationModal"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch flip-card"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="icon-box icon-box-cyan">
                    <div class="icon">
                      <img alt="" src="assets/icons/technologies/net.svg" />
                    </div>
                    <h4 class="title">
                      <Link>.NET Developer</Link>
                    </h4>
                    <p class="description">
                      Position : Senior Software Engineer <br />
                      Experience : 5 - 8 years
                      <br />
                      Location : Hyderabad <br />
                      Qualification : BE / B.Tech/ Any other degree
                    </p>
                    <div class="turn-around-arrow">
                      <i class="bi bi-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div class="flip-card-back">
                  <p class="description">
                    Are you a coding maestro with a knack for building robust
                    applications using the .NET framework? We're on the lookout
                    for a talented .NET Developer to help us create cutting-edge
                    software solutions.
                  </p>
                  <button
                    class="btn btn-primary activateButton read-more-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#applicationModal"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch flip-card"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="icon-box icon-box-green">
                    <div class="icon">
                      <img
                        alt=""
                        src="assets/icons/technologies/full-stack.svg"
                      />
                    </div>
                    <h4 class="title">
                      <Link>Full Stack developer</Link>
                    </h4>
                    <p class="description">
                      Position : Full Stack Developer <br />
                      Experience : 4 - 8 years
                      <br />
                      Location : Hyderabad <br />
                      Qualification : BE / B.Tech/ Any other degree
                    </p>
                    <div class="turn-around-arrow">
                      <i class="bi bi-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div class="flip-card-back">
                  <p class="description">
                    Are you a tech-savvy individual with a passion for crafting
                    seamless digital experiences from front-end to back-end?
                    We're seeking a talented Full Stack Developer to help us
                    create innovative and user-friendly web solutions.
                  </p>
                  <button
                    class="btn btn-primary activateButton read-more-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#applicationModal"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3 d-flex align-items-stretch flip-card"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="icon-box icon-box-blue">
                    <div class="icon">
                      <img alt="" src="assets/icons/technologies/react.svg" />
                    </div>
                    <h4 class="title">
                      <Link>React Developer</Link>
                    </h4>
                    <p class="description">
                      Position : React Developer <br />
                      Experience : 2 - 3 years
                      <br />
                      Location : Hyderabad <br />
                      Qualification : BE / B.Tech/ Any other degree
                    </p>
                    <div class="turn-around-arrow">
                      <i class="bi bi-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div class="flip-card-back">
                  <p class="description">
                    Are you a coding enthusiast with a passion for building
                    sleek and interactive user interfaces using React? We're
                    searching for a skilled React Developer to be part of our
                    innovative team, creating next-level web experiences.
                  </p>
                  <button
                    class="btn btn-primary activateButton read-more-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#applicationModal"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="applicationModal" className="modal contact fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div id="career" className="appointment apply">
              <h2>Application Form</h2>
              <form id="careerForm" role="form" className="php-email-form">
                <input type="hidden" name="career_id" id="career_id" />
                <div className="row">
                  <div className="col-md-4 form-group">
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="firstName"
                      placeholder="Enter First Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group">
                    <input
                      type="text"
                      name="middleName"
                      className="form-control"
                      id="middleName"
                      placeholder="Enter Middle Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      id="lastName"
                      placeholder="Enter Last Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      data-rule="email"
                      data-msg="Please enter a valid email"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      id="phone"
                      placeholder="Your Mobile Number"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="tel"
                      className="form-control"
                      name="exp"
                      id="exp"
                      placeholder="Experience (in months)"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="highest"
                      id="highest"
                      placeholder="Highest Qualification"
                      data-rule="text"
                      data-msg="Please enter a valid text"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="Location"
                      id="Location"
                      placeholder="Your Work Location"
                      data-rule="text"
                      data-msg="Please enter a valid Location"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="current"
                      id="current"
                      placeholder="Your Current company"
                      data-rule="text"
                      data-msg="Please enter a valid Company name"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="ctc"
                      id="ctc"
                      placeholder="Your current CTC"
                      data-rule="text"
                      data-msg="Please enter valid CTC"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="expCTC"
                      id="expCTC"
                      placeholder="Your Expected CTC"
                      data-rule="text"
                      data-msg="Please enter a valid CTC"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="notice"
                      id="notice"
                      placeholder="Notice period (in days)"
                      data-rule="text"
                      data-msg="Please enter a valid data"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-12 form-group mt-3 mt-md-0 d-flex justify-content-center">
                    <label htmlFor="resume" className="upload-resume">
                      Upload Resume :
                      <input
                        type="file"
                        className="form-control"
                        name="resume"
                        id="resume"
                        data-rule="text"
                        data-msg="Please enter a valid CTC"
                      />
                    </label>
                    <div className="validate" />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" />
                  <div className="sent-message">
                    Your job application has been sent successfully. Thank you!
                  </div>
                </div>
                <div className="btn-control">
                  <div className="button text-center">
                    <button
                      type="button"
                      className="btn btn-secondary button text-center"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="button text-center">
                    <button type="submit" id="btnSendCareer">
                      Apply
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <section className="careers-benefits">
        <div className="container">
          <div className="section-title">
            <h2>Benefits of working at Binlytix</h2>
            <p>
              We at Binlytix offers stability, growth opportunities, financial
              security, and recognition. Ensuring a supportive environment for
              personal and professional development, with perks, networking, and
              a work-life balance, fostering a sense of inclusivity.
            </p>
          </div>
          <div className="row g-3">
            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink d-flex flex-column align-items-center p-3">
                <div className="icon">
                  <img alt="" src="assets/icons/careers/creative.svg" />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h4 className="title">Innovation at its Core</h4>
                  <p className="description">
                    Step into an environment that thrives on innovation.
                    BINLYTIX is a hub of creativity, where bold ideas are not
                    only welcomed but encouraged. Be a part of our pioneering
                    spirit and contribute to shaping the future.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink d-flex flex-column align-items-center p-3">
                <div className="icon">
                  <img alt="" src="assets/icons/careers/endless.svg" />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h4 className="title">Endless Opportunities</h4>
                  <p className="description">
                    Your professional development matters to us. Through
                    workshops, mentorship programs, and continuous learning, we
                    empower you to expand your skill set, broaden your horizons,
                    and reach your full potential.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink d-flex flex-column align-items-center p-3">
                <div className="icon">
                  <img alt="" src="assets/icons/careers/team.svg" />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h4 className="title">A Culture of Collaboration</h4>
                  <p className="description">
                    At BINLYTIX, we believe in the power of teamwork. Our
                    collaborative culture fosters an atmosphere of open
                    communication, idea sharing, and mutual support. Together,
                    we achieve greatness.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink d-flex flex-column align-items-center p-3">
                <div className="icon">
                  <img
                    alt=""
                    src="assets/icons/careers/work-life-balance.svg"
                  />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h4 className="title">Work-Life Integration</h4>
                  <p className="description">
                    We understand the importance of balance. With flexible work
                    arrangements and wellness initiatives, we ensure you have
                    the support you need to excel both personally and
                    professionally.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink d-flex flex-column align-items-center p-3">
                <div className="icon">
                  <img alt="" src="assets/icons/careers/rewards.svg" />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h4 className="title">Rewards and Recognition</h4>
                  <p className="description">
                    Your dedication deserves to be acknowledged. We offer
                    competitive compensation packages, performance bonuses, and
                    recognition programs to celebrate your contributions.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div className="icon-box icon-box-pink d-flex flex-column align-items-center p-3">
                <div className="icon">
                  <img alt="" src="assets/icons/careers/career-advance.svg" />
                </div>
                <div className="content d-flex flex-column align-items-center">
                  <h4 className="title">Career Advancement</h4>
                  <p className="description">
                    Your journey with us is just the beginning. We provide clear
                    paths for career growth, giving you the opportunities and
                    resources to take your career to new heights.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hiring-process">
        <div className="container">
          <div className="section-title">
            <h2>Hiring Process</h2>
          </div>
          <div className="row" data-aos="fade-up">
            <div
              className="col-lg-3 col-md-6 col-sm-12 step"
              data-aos="fade-up"
            >
              <div className="step-wrap d-flex flex-column align-items-center justify-content-start">
                <div className="img">
                  <img alt="" src="assets/icons/careers/interview.svg" />
                </div>
                <div className="content d-flex flex-column align-items-center justify-content-between p-3">
                  <h3>Technical Interview</h3>
                  <p>
                    If your application meets our criteria, we'll invite you to
                    an initial interview. This may be conducted over the phone,
                    video call, or in-person, depending on your location. During
                    this interview, we'll delve into your background, skills,
                    and motivations.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 step"
              data-aos="fade-up"
            >
              <div className="step-wrap d-flex flex-column align-items-center justify-content-start">
                <div className="img">
                  <img alt="" src="assets/icons/careers/practical.svg" />
                </div>
                <div className="content d-flex flex-column align-items-center justify-content-between p-3">
                  <h3>Practical Test</h3>
                  <p>
                    You'll participate in a focused technical assessment that
                    aligns with the role you're applying for. This could involve
                    coding challenges, system design exercises, or other
                    relevant tasks. Our goal is to gauge your hands-on skills
                    and approach to real-world scenarios.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 step"
              data-aos="fade-up"
            >
              <div className="step-wrap d-flex flex-column align-items-center justify-content-start">
                <div className="img">
                  <img
                    alt=""
                    src="assets/icons/careers/management-interview.svg"
                  />
                </div>
                <div className="content d-flex flex-column align-items-center justify-content-between p-3">
                  <h3>Management Interview</h3>
                  <p>
                    We we aim to understand how your leadership style aligns
                    with our company culture and values. This is your
                    opportunity to showcase your management prowess and discuss
                    how you envision contributing to our team's success.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 step"
              data-aos="fade-up"
            >
              <div className="step-wrap d-flex flex-column align-items-center justify-content-start">
                <div className="img">
                  <img alt="" src="assets/icons/careers/onboarding.svg" />
                </div>
                <div className="content d-flex flex-column align-items-center justify-content-between p-3">
                  <h3>Final selection</h3>
                  <p>
                    Congratulations! If you're the right fit for our team and
                    the role, we'll extend a formal offer. You'll receive
                    details about your compensation package, benefits, and other
                    relevant information.Once you've accepted our offer, our
                    onboarding process will kick in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
}
export default index;
