function AboutUs() {
  return (
    <>
      <section id="home-about-us" data-aos="fade-up">
        <div className="container">
          <div className="row g-3 header-about-us">
            <div className="col-lg-6 col-md-12 icon-box p-3">
              <div className="icon">
                <img alt="" src="assets/icons/about-us-header/growth.svg" />
              </div>
              <div className="content">
                <h4 className="title">Rapid Growth</h4>
                <p className="description">
                  We are a fast growing organization and we specialize in
                  providing cutting-edge technology solutions to businesses
                  across a wide range of industries.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 icon-box p-3">
              <div className="icon">
                <img alt="" src="assets/icons/about-us-header/creative.svg" />
              </div>
              <div className="content">
                <h4 className="title">Innovation</h4>
                <p className="description">
                  We are a team of experts dedicated to help businesses to grow
                  by providing them with innovative Software Applications
                  Development and Maintenance, Data Analytics, Business
                  Intelligence &amp; Cloud Services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-us why-us section-bg" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 video-box">
              <img
                alt=""
                src="assets/icons/about-us-header/vision-mission.svg"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-7 content">
              <div className="icon-box">
                <div className="icon">
                  <img alt="" src="assets/icons/about-us-header/vision.svg" />
                </div>
                <div className="icon-box-content">
                  <h3 className="title">Our Vision</h3>
                  <p className="description">
                    <i className="bx bxs-quote-alt-left quote-icon-left" />
                    To be an organization of global standing for developing
                    modern technological world
                    <i className="bx bxs-quote-alt-right quote-icon-right" />
                  </p>
                </div>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <img alt="" src="assets/icons/about-us-header/mission.svg" />
                </div>
                <div className="icon-box-content">
                  <h3 className="title">Our Mission</h3>
                  <p className="description">
                    <i className="bx bxs-quote-alt-left quote-icon-left" />
                    To help all level of organization to boost their business,
                    and grow exponentially
                    <i className="bx bxs-quote-alt-right quote-icon-right" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="about-us core-values section-bg">
        <div className="home-core-values">
          <h3 className="title">Our Core Values</h3>
          <ul className="items">
            <li>
              <img alt="" src="assets/icons/about-us-header/trust.svg" />
              Trust
            </li>
            <li>
              <img alt="" src="assets/icons/about-us-header/innovative.svg" />
              Innovation
            </li>
            <li>
              <img alt="" src="assets/icons/about-us-header/respect.svg" />
              Respect
            </li>
            <li>
              <img alt="" src="assets/icons/about-us-header/inclusivity.svg" />
              Inclusivity
            </li>
            <li>
              <img alt="" src="assets/icons/about-us-header/passion.svg" />
              Passion
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default AboutUs;
